import React, { useState, useEffect } from "react";
import { Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import {
    withAuthorization,
    AuthUserContext,
    withEmailVerification,
} from "../../Session";
import MaterialTable from "material-table";
import { withFirebase } from "../../components/Firebase";
import { tableIcons } from "../../components/TableIcons/TableIcons";
import useStyles from "./styles";

const ADMIN = "ADMIN";
const WS = "WS";

const Location = () => {
    var classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h3" className={classes.title}>
                Lokasjon
            </Typography>
            <Locations />
        </React.Fragment>
    );
};

function LocationBase(props) {
    var classes = useStyles();

    var [isLoading, setIsLoading] = useState(false);
    var [text, setText] = useState("");
    var [locations, setLocations] = useState([]);
    var [error, setError] = useState(null);

    useEffect(() => {
        onListenForLocations();
    }, []);
    const onChangeText = event => {
        setError("");
        setText(event.target.value);
    };

    const onCreateLocation = (event, authUser) => {
        setIsLoading(true);

        const isDuplicate = locations.some(
            location => location.text.toUpperCase() === text.toUpperCase(),
        );

        if (isDuplicate) {
            setError(`Det finnes allerede ${text}`);
            setIsLoading(false);
            setText("");
            event.preventDefault();
            return;
        }

        props.firebase.locations().push({
            userId: authUser.uid,
            text: text,
            createdAt: props.firebase.serverValue.TIMESTAMP,
        });
        setIsLoading(false);
        setText("");
        event.preventDefault();
    };

    const onListenForLocations = () => {
        setIsLoading(true);

        props.firebase
            .locations()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const locationObject = snapshot.val();
                if (locationObject) {
                    const locationList = Object.keys(locationObject).map(
                        key => ({
                            ...locationObject[key],
                            uid: key,
                        }),
                    );

                    setIsLoading(false);
                    setLocations(locationList);
                } else {
                    setIsLoading(false);
                    setLocations(locations);
                }
            });
    };

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <div>
                    <div>{isLoading && <CircularProgress size={26} />}</div>
                    <div className={classes.error}>
                        {error != null && error}
                    </div>
                    <form
                        onSubmit={event => onCreateLocation(event, authUser)}
                        className={classes.myForm}
                    >
                        <input
                            type="text"
                            value={text}
                            onChange={onChangeText}
                            className={classes.input}
                        />
                        <button type="submit" className={classes.mybutton}>
                            Lagre
                        </button>
                    </form>
                    {locations ? (
                        <LocationList
                            locations={locations}
                            authUser={authUser}
                            firebase={props.firebase}
                        />
                    ) : (
                        <div> There are no profiles yet </div>
                    )}
                </div>
            )}
        </AuthUserContext.Consumer>
    );
}

const LocationList = ({ locations, authUser, firebase }) => {
    let locationList = [];
    locations.forEach(location => {
        locationList.push({ uid: location.uid, text: location.text });
    });

    const columns = [
        { title: "Uid", field: "uid", hidden: true },
        { title: "Lokasjon", field: "text" },
    ];

    return (
        <MaterialTable
            icons={tableIcons}
            title=""
            columns={columns}
            data={locationList}
            localization={{
                pagination: {
                    labelDisplayedRows: "{from}-{to} av {count}",
                    labelRowsSelect: "rad",
                },
                header: {
                    actions: "Handlinger",
                },
                body: {
                    emptyDataSourceMessage: "Ingen lokasjon ennå",
                    editRow: {
                        saveTooltip: "Lagre",
                        cancelTooltip: "Avbryt",
                        deleteText:
                            "Er du sikker på at du vil slette denne lokasjon?",
                    },
                    deleteTooltip: "Slett",
                    editTooltip: "Rediger",
                },
                toolbar: {
                    searchPlaceholder: "Søk",
                    searchTooltip: "Søk",
                },
            }}
            editable={{
                onRowUpdate: (newData, oldData) =>
                    new Promise(resolve => {
                        if (oldData) {
                            firebase.location(newData.uid).set({ ...newData });
                            resolve();
                        }
                    }),
                onRowDelete: oldData =>
                    new Promise(resolve => {
                        firebase.location(oldData.uid).remove();
                        resolve();
                    }),
            }}
        />
    );
};

const Locations = withFirebase(LocationBase);
const condition = authUser =>
    authUser &&
    authUser.roles &&
    (authUser.roles[ADMIN] === ADMIN || authUser.roles[WS] === WS);

export default compose(
    withAuthorization(condition),
    withEmailVerification,
    withFirebase,
)(Location);

import React, { useState } from "react";
import {
    CircularProgress,
    Typography,
    Button,
    TextField,
    Fade,
} from "@material-ui/core";
import { compose } from "recompose";
import useStyles from "./styles";
import { useUserDispatch, loginUser } from "../../context/UserContext";
import { withFirebase } from "../../components/Firebase";
import * as ROUTES from "../../constants/routes";
import { withRouter } from "react-router-dom";
import LoginErrors from './errors';

function LoginBase(props) {
    var classes = useStyles();

    // global
    var userDispatch = useUserDispatch();

    // local
    var [isLoading, setIsLoading] = useState(false);
    var [error, setError] = useState(null);
    var [emailValue, setEmailValue] = useState("");
    var [passwordValue, setPasswordValue] = useState("");
    var [forgotPassword, setForgotPassword] = useState(false);

    async function getRoles(props, socialAuthUser) {
        const dprops = props.firebase
            .user(socialAuthUser.user.uid)
            .once("value")
            .then(function(snapshot) {
                return snapshot.val() && snapshot.val().roles;
            });

        return await dprops.then(v => Promise.resolve(v));
    }

    function signInWithGoogle(event) {
        props.firebase
            .doSignInWithGoogle()
            .then(async socialAuthUser => {
                localStorage.setItem("id_token", 1);
                userDispatch({ type: "LOGIN_SUCCESS" });

                const roles = await getRoles(props, socialAuthUser);

                // Create a user in your Firebase Realtime Database too
                return props.firebase.user(socialAuthUser.user.uid).set({
                    username: socialAuthUser.user.displayName,
                    email: socialAuthUser.user.email,
                    roles: roles ? roles : { WS: "WS" },
                });
            })
            .then(() => {
                setError(null);
                console.log("routing");
                props.history.push(ROUTES.APP);
            })
            .catch(error => {
                setError(error);
            });
        event.preventDefault();
    }

    const onForgotPassword = () => {
        setForgotPassword(true);
    };

    const sendVerficationEmail = () => {
        props.firebase.doPasswordReset(emailValue).then(r => {
            setError(null);
        }).catch(error => {
            setError(LoginErrors(error.code));
            console.log(error.message);
        });

        setForgotPassword(false);
    };

    return (
        <React.Fragment>
           
            <Typography variant="h4" className={classes.greeting}>
                Velkommen
            </Typography>
            
            <Fade in={error ? true : false}>
                <Typography color="secondary" className={classes.errorMessage}>
                    {error}
                </Typography>
            </Fade>
            {!forgotPassword && (
                <form>
                    <TextField
                        id="email"
                        InputProps={{
                            classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                            },
                        }}
                        value={emailValue}
                        onChange={e => setEmailValue(e.target.value)}
                        margin="normal"
                        placeholder="E-postadresse"
                        type="email"
                        fullWidth
                    />
                    <TextField
                        id="password"
                        InputProps={{
                            classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                            },
                        }}
                        value={passwordValue}
                        onChange={e => setPasswordValue(e.target.value)}
                        margin="normal"
                        placeholder="Passord"
                        type="password"
                        fullWidth
                    />
                    <div className={classes.formButtons}>
                        {isLoading ? (
                            <CircularProgress
                                size={26}
                                className={classes.loginLoader}
                            />
                        ) : (
                            <Button
                                disabled={
                                    emailValue.length === 0 ||
                                    passwordValue.length === 0
                                }
                                onClick={() =>
                                    loginUser(
                                        userDispatch,
                                        emailValue,
                                        passwordValue,
                                        props.history,
                                        setIsLoading,
                                        setError,
                                        props.firebase,
                                    )
                                }
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                            >
                                Fortsett
                            </Button>
                        )}
                        <Button
                            color="primary"
                            size="large"
                            className={classes.forgetButton}
                            onClick={onForgotPassword}
                        >
                            Glemt passord?
                        </Button>
                    </div>
                </form>
            )}
            {forgotPassword && (
                <div>
                    <TextField
                        id="email"
                        InputProps={{
                            classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                            },
                        }}
                        value={emailValue}
                        onChange={e => setEmailValue(e.target.value)}
                        margin="normal"
                        placeholder="E-postadresse"
                        type="email"
                        fullWidth
                    />
                    <div className={classes.formButtons}>
                        <Button
                            color="primary"
                            size="large"
                            variant="contained"
                            className={classes.forgetButton}
                            onClick={sendVerficationEmail}
                        >
                            Send E-postbekreftelse
                        </Button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

const Login = compose(withRouter, withFirebase)(LoginBase);

export default Login;

import React, { useState } from "react";
import {
  Grid,
  Typography,
  Tabs,
  Tab
} from "@material-ui/core";
import { withRouter } from "react-router-dom";

import useStyles from "./styles";
import natna from "./natna.png";

import Login from "./Login";

function Authenticate(props) {
  var classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <img src={natna} alt="logo" className={classes.logoImage} />
          <Login />
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2020 Natna. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Authenticate);

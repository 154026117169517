const Status = {
    notBegin: "ikke påbegynt",
    delivered: "Stål Levert",
    begin: "Påbegynt",
    ten: "10% ferdig",
    twenty: "20% ferdig",
    thirty: "30% ferdig",
    forty: "40% ferdig",
    fifty: "50% ferdig",
    sixty: "60% ferdig",
    seventy: "70% ferdig",
    eighty: "80% ferdig",
    nighty: "90% ferdig",
    oneHundred: "100% ferdig",
    doneFromWorkshope: "Ferdig fra verksted",
};

export default Status;

import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  materialTable: {
    paddingTop: "30px"
  },
  title: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  myForm : {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "30px",
    paddingTop: "30px"
  },
  input : {
    flex: "2 0 0",
    padding: "12px 20px",
    margin: "8px 8px 0 0",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  mybutton : {
    flex: "1 0 0",
    margin: "8px 0 0 0",
  },
  error : {
    color: "red"
  },
  select: {
    width: '100%',
}

}));

import React from "react";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return { ...state, isAuthenticated: true };
        case "LOGIN_FAILURE":
            return { ...state, isAuthenticated: false };
        case "SIGN_OUT_SUCCESS":
            return { ...state, isAuthenticated: false };
        case "CREATE_USER_SUCCESS":
            return { ...state, isAuthenticated: false };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function UserProvider({ children }) {
    var [state, dispatch] = React.useReducer(userReducer, {
        isAuthenticated: !!localStorage.getItem("id_token"),
    });

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
}

function useUserState() {
    var context = React.useContext(UserStateContext);
    if (context === undefined) {
        throw new Error("use UserState must be used within a UserProvider");
    }
    return context;
}

function useUserDispatch() {
    var context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error("useUserDispatch must be used within a UserProvider");
    }
    return context;
}

export {
    UserProvider,
    useUserState,
    useUserDispatch,
    loginUser,
    signOut,
    createUser,
};

// ###########################################################

function createUser(
    dispatch,
    email,
    password,
    history,
    setIsLoading,
    setError,
    firebase,
) {
    firebase
        .doCreateUserWithEmailAndPassword(email, password)
        .then(() => {
            console.log("user created");

            setError(null);
            setIsLoading(false);
            
            dispatch({ type: "CREATE_USER_SUCCESS" });

            history.push("/login");
        })
        .catch(err => {
            console.log("Failed", err);
            dispatch({ type: "LOGIN_FAILURE" });
            setError(true);
            setIsLoading(false);
        });
}

function existingUser(firebase, uid) {
  let users = null;
    firebase
        .user(uid)
        .once('value')
        .then((snapshot) => {
        const dbUser = snapshot.val();

        });
    return users;
}

function loginUser(
    dispatch,
    email,
    password,
    history,
    setIsLoading,
    setError,
    firebase,
) {
    setError(false);
    setIsLoading(true);

    firebase
        .doSignInWithEmailAndPassword(email, password)
        .then(response => {
            localStorage.setItem("id_token", 1);
            const { uid } = firebase.auth.currentUser;

            firebase
                .user(uid)
                .once('value')
                .then((snapshot) => {
                    const dbUser = snapshot.val();

                    if (!dbUser) {
                        
                        firebase.user(uid).set({
                            username: email,
                            email: email,
                            roles: { PL: "PL" },
                        });
                    }
                    setError(null);
                    setIsLoading(false);
                    dispatch({ type: "LOGIN_SUCCESS" });

                history.push("/app/dashboard");

            }).catch(error => {
                console.log(error);
            
            //     firebase.user(uid).set({
            //         username: email,
            //         email: email,
            //         roles: { PL: "PL" },
            //     });

            //     setError(null);
            //     setIsLoading(false);
            //     dispatch({ type: "LOGIN_SUCCESS" });
            // history.push("/app/dashboard");
            });
        })
        .catch(error => {
            console.log("Failed", error);
            dispatch({ type: "LOGIN_FAILURE" });
            setError(true);
            setIsLoading(false);
        });

    // if (!!login && !!password) {
    //   setTimeout(() => {
    //     localStorage.setItem('id_token', 1)
    //     setError(null)
    //     setIsLoading(false)
    //     dispatch({ type: 'LOGIN_SUCCESS' })

    //     history.push('/app/dashboard')
    //   }, 2000);
    // } else {
    //   dispatch({ type: "LOGIN_FAILURE" });
    //   setError(true);
    //   setIsLoading(false);
    // }
}

function signOut(dispatch, history) {
    localStorage.removeItem("id_token");
    localStorage.removeItem('authUser');   
    dispatch({ type: "SIGN_OUT_SUCCESS" });
    history.push("/login");
}

import React, { useState, useEffect } from 'react';
import { Typography, CircularProgress} from "@material-ui/core";
import MaterialTable from 'material-table';
import { compose } from "recompose";
import { withAuthorization, AuthUserContext, withEmailVerification} from '../../Session';
import { withFirebase } from '../../components/Firebase';
import { tableIcons } from '../../components/TableIcons/TableIcons';
import useStyles from "./styles";

const ADMIN = 'ADMIN';

const ROLES = {ADMIN: "ADMIN", PL: "PL", WS: "WS", USER: "USER"};

const User = () => {
    var classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h3" className={classes.title}>
                Bruker
            </Typography>
            <Users />
        </React.Fragment>
    );
}

function UserBase (props) {

    var [isLoading, setIsLoading] = useState(false);
    var [users, setUsers] = useState([]);

    useEffect(() => {
        onListenForUsers()
    }, []);
    

    const onListenForUsers = () => {
        setIsLoading(true);

        props.firebase
        .users()
        .orderByChild('createdAt')
        .on('value', (snapshot) => {
            const userObject = snapshot.val();
            if (userObject) {
                const userList = Object.keys(userObject).map(
                    (key) => ({
                        ...userObject[key],
                        uid: key,
                    }),
                );

                setIsLoading(false);
                setUsers(userList);
            } else {
                setIsLoading(false);
                setUsers(users);
            }
        });
    };

    return (
        <AuthUserContext.Consumer>
            {(authUser) => (
                <div>
                    <div>
                        {isLoading && <CircularProgress size={26} /> }
                    </div>
                    {users ? (
                        <UserList users={users} authUser={authUser} firebase={props.firebase} />
                    ): <div> There are no users yet </div>}
                </div>
            )}
        </AuthUserContext.Consumer>
    );
}

const UserList = ({users, authUser, firebase}) => {
    let userList = [];
    users.forEach(user => {
        const roles = Object.keys(user.roles).map(role => user.roles[role]).join(",");
        userList.push({uid: user.uid, email: user.email, username: user.username, roles: roles })
    });
    
    const columns = [
        { title: 'Uid', field: 'uid', editable: 'never', hidden: true},
        { title: 'Epost', field: 'email', editable: 'never' },
        { title: 'Bruker', field: 'username', editable: 'never' },
        { title: 'Role', field: 'roles', lookup: ROLES },
    ];

    return (
        <MaterialTable
            icons={tableIcons}
            title=""
            columns={columns}
            data={userList}
            localization={{
                pagination: {
                    labelDisplayedRows: "{from}-{to} av {count}",
                    labelRowsSelect: "rad"
                },
                header: {
                    actions: 'Handlinger'
                },
                body: {
                    emptyDataSourceMessage: 'Ingen bruker ennå',
                    editRow: {
                        saveTooltip: "Lagre",
                        cancelTooltip: "Avbryt",
                        deleteText: "Er du sikker på at du vil slette denne bruker?"
                    },
                    deleteTooltip: "Slett",
                    editTooltip: "Rediger"
                },
                toolbar: {
                    searchPlaceholder: "Søk",
                    searchTooltip: 'Søk'
                }

            }}
            editable={{
            onRowUpdate: (newData, oldData) =>
                new Promise((resolve) => {
                    if (oldData) {
                        if (oldData.roles !== newData.roles) {
                            newData = {...newData, roles: {[newData.roles]: newData.roles}}
                        }
                        firebase.user(newData.uid).set({...newData});
                        resolve();
                    }
                }),
            onRowDelete: (oldData) =>
                new Promise((resolve) => {
                    firebase.user(oldData.uid).remove();
                    resolve();
                }),
            }}
      />
    );
};

const Users = withFirebase(UserBase);
const condition = authUser =>
    authUser && authUser.roles && (authUser.roles[ADMIN] === ADMIN);

export default compose(
    withAuthorization(condition),
    withEmailVerification,
  )(User);
import React, { useState, useEffect } from "react";
import {
    Typography,
    Button,
    CircularProgress,
    TextField,
    withStyles,
    Grid,
    makeStyles,
    createStyles,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import { compose } from "recompose";
import {
    withAuthorization,
    AuthUserContext,
    withEmailVerification,
} from "../../Session";
import MaterialTable from "material-table";
import { withFirebase } from "../../components/Firebase";
import { tableIcons } from "../../components/TableIcons/TableIcons";
import useStyles from "./styles";
import allProfiles from "../../data/allProfiles.json";
import Menus from "../items/data.json";

const ADMIN = "ADMIN";
const WS = "WS";
const PL = "PL";

const Profile = () => {
    var classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h3" className={classes.title}>
                Profil
            </Typography>
            <Profiles />
        </React.Fragment>
    );
};

function ProfileBase(props) {
    var classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [profile, setProfile] = useState("");
    const [profiles, setProfiles] = useState([]);
    const [error, setError] = useState(null);
    const [profileType, setProfileType] = useState("");
    const [weight, setWeight] = useState(0);

    useEffect(() => {
        onListenForProfiles();
    }, []);

    const onChangeProfile = event => {
        setError("");
        setProfile(event.target.value);
    };

    const onChangeWeight = event => {
        setError("");
        setWeight(event.target.value);
    };

    const handlePofileType = event => {
        setError("");
        setProfileType(event.target.value);
    };

    const onCreateProfile = (event, authUser) => {
        setIsLoading(true);

        const isDuplicate = profiles.some(
            pro => pro.name.toUpperCase() === profile.toUpperCase(),
        );

        if (isDuplicate) {
            setError(`Det finnes allerede ${profile}`);
            setIsLoading(false);
            setProfile("");
            event.preventDefault();
            return;
        }

        // props.firebase.profiles().push({
        //     userId: authUser.uid,
        //     name: profile,
        //     weight: weight,
        //     type: profileType
        //     createdAt: props.firebase.serverValue.TIMESTAMP,
        // });
        // setIsLoading(false);
        // setProfile("");
        // event.preventDefault();
    };

    const onListenForProfiles = () => {
        setIsLoading(true);

        props.firebase
            .profiles()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const profileObject = snapshot.val();
                if (profileObject) {
                    const profileList = Object.keys(profileObject).map(key => ({
                        ...profileObject[key],
                        uid: key,
                    }));

                    setIsLoading(false);
                    setProfiles(profileList);
                } else {
                    setIsLoading(false);
                    setProfiles(profiles);
                }
            });
    };

    const onClickImport = (event, authUser) => {
        event.preventDefault();
        event.stopPropagation();
        console.log("Importing");
        allProfiles.forEach(a => {
            props.firebase.profiles().push({
                userId: authUser.uid,
                name: a.Profile,
                weight: a.Weight,
                type: a.Type,
                createdAt: props.firebase.serverValue.TIMESTAMP,
            });
        });
    };

    const getProfileTypes = () => {
        const structural = Menus.Structural.map(p => p.label);
        const commercial = Menus.Commercial.map(p => p.label);
        const hollowed = Menus.Hollowed.map(p => p.label);
        return [...structural, ...commercial, ...hollowed];
    };

    const renderProfileTypes = () => {
        const profileTypes = getProfileTypes();
        return profileTypes.sort().map(pt => {
            return (
                <MenuItem key={pt} value={pt}>
                    {pt}
                </MenuItem>
            );
        });
    };

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <div>
                    <div>
                        <div>{isLoading && <CircularProgress size={26} />}</div>
                        <div className={classes.error}>
                            {error != null && error}
                        </div>
                        <div>
                            <form
                                onSubmit={event =>
                                    onCreateProfile(event, authUser)
                                }
                                className={classes.myForm}
                            >
                                <Grid
                                    container
                                    spacing={3}
                                    style={{ paddingTop: "12px" }}
                                >
                                    <Grid item xs={12} sm={12} md={3}>
                                        <InputLabel>Profil</InputLabel>
                                        <TextField
                                            type="text"
                                            value={profile}
                                            onChange={onChangeProfile}
                                            className={classes.select}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <InputLabel>Profiltype</InputLabel>
                                        <Select
                                            value={profileType}
                                            onChange={handlePofileType}
                                            className={classes.select}
                                        >
                                            {renderProfileTypes()}
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <InputLabel>Vekt</InputLabel>
                                        <TextField
                                            type="number"
                                            value={weight}
                                            onChange={onChangeWeight}
                                            className={classes.select}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={classes.select}
                                        >
                                            Lagre
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        {profiles ? (
                            <ProfileList
                                profiles={profiles}
                                authUser={authUser}
                                firebase={props.firebase}
                                classes={classes}
                            />
                        ) : (
                            <div> Ingen profil er registert </div>
                        )}
                    </div>
                    {/* <div> <Button variant="contained" color="primary" onClick={event => onClickImport(event, authUser)}> Import </Button></div> */}
                </div>
            )}
        </AuthUserContext.Consumer>
    );
}

/**
 * 
 * @param {"pr.m": 17.15,
      "10": 172,
      "12": "206",
      "15": 257,
      "18": null,
      "Høyde": 96,
      "Bredde": 100,
      "Steg": 5,
      "Flens": 8} param0 
 */
const ProfileList = ({ profiles, authUser, firebase, classes }) => {
    let profileList = [];
    profiles.forEach(profile => {
        profileList.push({
            uid: profile.uid,
            name: profile.name,
            weight: profile.weight,
        });
    });

    const columns = [
        { title: "Uid", field: "uid", hidden: true },
        { title: "Profil", field: "name" },
        { title: "Vekt kg/m", field: "weight" },
    ];

    return (
        <div className={classes.materialTable}>
            <MaterialTable
                icons={tableIcons}
                title=""
                columns={columns}
                data={profileList}
                localization={{
                    pagination: {
                        labelDisplayedRows: "{from}-{to} av {count}",
                        labelRowsSelect: "rad",
                    },
                    header: {
                        actions: "Handlinger",
                    },
                    body: {
                        emptyDataSourceMessage: "Ingen profil ennå",
                        editRow: {
                            saveTooltip: "Lagre",
                            cancelTooltip: "Avbryt",
                            deleteText:
                                "Er du sikker på at du vil slette denne profile?",
                        },
                        deleteTooltip: "Slett",
                        editTooltip: "Rediger",
                    },
                    toolbar: {
                        searchPlaceholder: "Søk",
                        searchTooltip: "Søk",
                    },
                }}
                // editable={{
                //     onRowUpdate: (newData, oldData) =>
                //         new Promise(resolve => {
                //             if (oldData) {
                //                 firebase
                //                     .profile(newData.uid)
                //                     .set({ ...newData });
                //                 resolve();
                //             }
                //         }),
                //     onRowDelete: oldData =>
                //         new Promise(resolve => {
                //             firebase.profile(oldData.uid).remove();
                //             resolve();
                //         }),
                // }}
            />
        </div>
    );
};

const Profiles = withFirebase(ProfileBase);
const condition = authUser =>
    authUser &&
    authUser.roles &&
    (authUser.roles[ADMIN] === ADMIN ||
        authUser.roles[WS] === WS ||
        authUser.roles[PL] === PL);

export default compose(
    withAuthorization(condition),
    withEmailVerification,
    withFirebase,
)(Profile);

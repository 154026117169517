import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../components/Firebase';

const withEmailVerification = (Component) => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);
      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() =>  {
          this.setState({ isSent: true });
        });
      }

    render() {
      const needsEmailVerification = (authUser) => false;
        // authUser &&
        // !authUser.emailVerified &&
        // authUser.providerData
        //   .map((provider) => provider.providerId)
        //   .includes('password');

      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            needsEmailVerification(authUser) ? (
              <div>
                {this.state.isSent ? (
                  <p>
                    E-postbekreftelse sendt: Sjekk e-post (Spam
                     mappe inkludert) for bekreftelse av e-post.
                     Oppdater denne siden når du har bekreftet e-posten din.
                  </p>
                ) : (
                  <p>
                    Bekreft e-postmeldingen din: Sjekk e-postene (søppelpostmappen
                     inkludert) for bekreftelse via e-post eller sending
                     en annen e-postbekreftelse.
                  </p>
                )}
                <button
                  type="button"
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}
                >
                  Send E-postbekreftelse
                </button>
              </div>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;

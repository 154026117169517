import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { withFirebase } from "../../components/Firebase";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import { tableIcons } from "../../components/TableIcons/TableIcons";
import Add from "@material-ui/icons/Add";
import ProjectDetailForm from "./ProjectDetailForm";
import { AuthUserContext } from "../../Session";
import CircularProgress from "@material-ui/core/CircularProgress";

const ProgressDetail = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState({});
    const [progressDetails, setProgressDetails] = useState({});
    const progressId = props.match.params.id;

    const [ShowProjectDetailDialog, setShowProjectDetailDialog] = useState(
        false,
    );

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await onListenForItems();
            setIsLoading(false);
        };

        fetchData();
    }, []);

    const onListenForItems = async () => {
        props.firebase
            .progress(progressId)
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const itemObject = snapshot.val();
                if (itemObject) {
                    setIsLoading(false);
                    setProgress(itemObject);
                } else {
                    setIsLoading(false);
                    setProgress(progress);
                }
            });

        props.firebase
            .progressDetails()
            .orderByChild("progressId")
            .equalTo(progressId)
            .on("value", snapshot => {
                const itemObject = snapshot.val();
                if (itemObject) {
                    const itemList = Object.keys(itemObject).map(key => ({
                        ...itemObject[key],
                        uid: key,
                    }));

                    setIsLoading(false);
                    setProgressDetails(itemList);
                } else {
                    setIsLoading(false);
                    setProgressDetails(progressDetails);
                }
            });
    };

    const handleOnProjectDetailFormClose = () => {
        setShowProjectDetailDialog(false);
    };

    const projectDetailform = (authUser, firebase) => {
        const progressData = {
            ...progress,
            uid: progressId,
        };
        return (
            <ProjectDetailForm
                open={true}
                onClose={handleOnProjectDetailFormClose}
                authUser={authUser}
                firebase={firebase}
                data={progressData}
            />
        );
    };

    const columns = [
        {
            title: "Ansv. Tegninger",
            field: "drawingResponsible",
        },
        {
            title: "Status",
            field: "status",
        },
        {
            title: "Pris/kg",
            field: "pricePerKg",
            render: rowData => (
                <span>
                    {rowData.pricePerKg ? <p> Kr {rowData.pricePerKg} </p> : 0}
                </span>
            ),
        },
        {
            title: "Timer i verksted",
            field: "workShopHours",
        },

        {
            title: "Gjenstående timeverk",
            field: "remainingHours",
        },
        {
            title: "Bemaning/Uke",
            field: "employeedPerWeek",
        },
        {
            title: "Beskrivelse",
            field: "description",
        },
        {
            title: "created at",
            field: "createdAt",
            render: rowData => (
                <span>
                    {new Date(rowData.createdAt).toLocaleDateString("no-NB")}
                </span>
            ),
        },
    ];

    const localization = {
        pagination: {
            labelDisplayedRows: "{from}-{to} av {count}",
            labelRowsSelect: "rad",
        },
        body: {
            emptyDataSourceMessage: "Ingen overflate ennå",
            editRow: {
                saveTooltip: "Lagre",
                cancelTooltip: "Avbryt",
                deleteText: "Er du sikker på at du vil slette denne overflate?",
            },
            deleteTooltip: "Slett",
            editTooltip: "Rediger",
        },
        toolbar: {
            searchPlaceholder: "Søk",
            searchTooltip: "Søk",
        },
    };

    const isEmptyData = () => {
        return Object.keys(progressDetails).length === 0;
    };
    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h2">
                            Prosjekt: {progress.projectName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3">
                            Prosjektnr: {progress.projectNr}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ PaddingTop: 30 }}>
                        <MaterialTable
                            icons={tableIcons}
                            title="Project progress"
                            columns={columns}
                            data={isEmptyData() ? [] : progressDetails}
                            localization={localization}
                            actions={[
                                {
                                    icon: Add,
                                    tooltip: "Add User",
                                    isFreeAction: true,
                                    onClick: event =>
                                        setShowProjectDetailDialog(true),
                                },
                            ]}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise(resolve => {
                                        if (oldData) {
                                            props.firebase
                                                .progressDetail(oldData.uid)
                                                .set({
                                                    ...newData,
                                                });
                                            resolve();
                                        }
                                    }),
                                onRowDelete: oldData =>
                                    new Promise(resolve => {
                                        props.firebase
                                            .progressDetail(oldData.uid)
                                            .remove();
                                        resolve();
                                    }),
                            }}
                        />
                    </Grid>
                    {ShowProjectDetailDialog &&
                        projectDetailform(authUser, props.firebase)}
                </Grid>
            )}
        </AuthUserContext.Consumer>
    );
};

export default withFirebase(ProgressDetail);

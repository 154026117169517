import React, { useState, useEffect } from "react";
import { Typography, CircularProgress } from "@material-ui/core";
import { compose } from "recompose";
import {
    withAuthorization,
    AuthUserContext,
    withEmailVerification,
} from "../../Session";
import MaterialTable from "material-table";
import { withFirebase } from "../../components/Firebase";
import { tableIcons } from "../../components/TableIcons/TableIcons";
import useStyles from "./styles";

const ADMIN = "ADMIN";
const WS = "WS";
const DeliveredComponent = () => {
    var classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h3" className={classes.title}>
                Levert
            </Typography>
            <Delivered />
        </React.Fragment>
    );
};

function ReservationBase(props) {
    var classes = useStyles();

    var [isLoading, setIsLoading] = useState(false);
    var [surfaces, setSurfaces] = useState([]);
    var [reserves, setReserves] = useState([]);
    var [error, setError] = useState(null);
    var [locations, setLocations] = useState([]);
    var [profiles, setProfiles] = useState([]);
    var [users, setUsers] = useState([]);

    useEffect(() => {
        onListenForReserves();
        onListenForProfiles();
        onListenForLocations();
        onListenForSurfaces();
        onListenForUsers();
    }, []);

    const onListenForProfiles = () => {
        setIsLoading(true);

        props.firebase
            .profiles()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const profileObject = snapshot.val();
                if (profileObject) {
                    const profileList = Object.keys(profileObject).map(key => ({
                        ...profileObject[key],
                        uid: key,
                    }));

                    setIsLoading(false);
                    setProfiles(profileList);
                } else {
                    setIsLoading(false);
                    setProfiles(profiles);
                }
            });
    };

    const onListenForLocations = () => {
        setIsLoading(true);

        props.firebase
            .locations()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const locationObject = snapshot.val();
                if (locationObject) {
                    const locationList = Object.keys(locationObject).map(
                        key => ({
                            ...locationObject[key],
                            uid: key,
                        }),
                    );

                    setIsLoading(false);
                    setLocations(locationList);
                } else {
                    setIsLoading(false);
                    setLocations(locations);
                }
            });
    };

    const onListenForSurfaces = () => {
        setIsLoading(true);

        props.firebase
            .surfaces()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const surfaceObject = snapshot.val();
                if (surfaceObject) {
                    const surfaceList = Object.keys(surfaceObject).map(key => ({
                        ...surfaceObject[key],
                        uid: key,
                    }));

                    setIsLoading(false);
                    setSurfaces(surfaceList);
                } else {
                    setIsLoading(false);
                    setSurfaces(surfaces);
                }
            });
    };

    const onListenForUsers = () => {
        setIsLoading(true);

        props.firebase
            .users()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const userObject = snapshot.val();
                if (userObject) {
                    const userList = Object.keys(userObject).map(key => ({
                        ...userObject[key],
                        uid: key,
                    }));

                    setIsLoading(false);
                    setUsers(userList);
                } else {
                    setIsLoading(false);
                    setUsers(users);
                }
            });
    };

    const onListenForReserves = () => {
        setIsLoading(true);

        props.firebase
            .reserves()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const surfaceObject = snapshot.val();
                if (surfaceObject) {
                    const surfaceList = Object.keys(surfaceObject).map(key => ({
                        ...surfaceObject[key],
                        uid: key,
                    }));

                    setIsLoading(false);
                    setReserves(surfaceList);
                } else {
                    setIsLoading(false);
                    setReserves(reserves);
                }
            });
    };

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <div>
                    <div>{isLoading && <CircularProgress size={26} />}</div>
                    <div className={classes.error}>
                        {error != null && error}
                    </div>
                    {reserves ? (
                        <ReservationList
                            reservations={reserves}
                            profiles={profiles}
                            locations={locations}
                            surfaces={surfaces}
                            users={users}
                            authUser={authUser}
                            firebase={props.firebase}
                            classes={classes}
                        />
                    ) : (
                        <div> There are no profiles yet </div>
                    )}
                </div>
            )}
        </AuthUserContext.Consumer>
    );
}

const ReservationList = ({
    reservations,
    profiles,
    locations,
    surfaces,
    users,
    authUser,
    firebase,
    classes,
}) => {
    let approvedList = [];

    reservations
        .sort((a, b) => b.createdAt - a.createdAt)
        .forEach(reservation => {
            const profile = profiles.find(p => p.uid === reservation.profile);
            const location = locations.find(
                l => l.uid === reservation.location,
            );
            const surface = surfaces.find(s => s.uid === reservation.surface);
            const user = users.find(s => s.uid === reservation.reservedBy);

            if (reservation.delivered) {
                approvedList.push({
                    uid: reservation.uid,
                    profileName: profile ? profile.name : "",
                    comment: reservation.comment,
                    location: location ? location.text : "",
                    surface: surface ? surface.text : "",
                    count: reservation.count,
                    length: reservation.length,
                    projectNumber: reservation.projectNumber,
                    reservedBy: user ? user.username : "",
                });
            }
        });

    const columns = [
        { title: "Uid", field: "uid", hidden: true },
        { title: "Profil", field: "profileName", editable: "never" },
        { title: "Lengde i mm", field: "length" },
        { title: "Antall", field: "count" },
        { title: "Lokasjon", field: "location", editable: "never" },
        { title: "Overflate", field: "surface", editable: "never" },
        { title: "ProjektNr", field: "projectNumber", editable: "never" },
        { title: "Reservert", field: "reservedBy", editable: "never" },
        { title: "Kommentar", field: "comment" },
    ];

    return (
        <div className={classes.materialTable}>
            <MaterialTable
                icons={tableIcons}
                title=""
                columns={columns}
                data={approvedList}
                localization={{
                    pagination: {
                        labelDisplayedRows: "{from}-{to} av {count}",
                        labelRowsSelect: "rad",
                    },
                    header: {
                        actions: "Handlinger",
                    },
                    body: {
                        emptyDataSourceMessage: "Ingen Reservasjon ennå",
                        editRow: {
                            saveTooltip: "Lagre",
                            cancelTooltip: "Avbryt",
                            deleteText:
                                "Er du sikker på at du vil slette denne reservasjon?",
                        },
                        deleteTooltip: "Slett",
                        editTooltip: "Rediger",
                    },
                    toolbar: {
                        searchPlaceholder: "Søk",
                        searchTooltip: "Søk",
                    },
                }}
                // editable={{
                //     onRowUpdate: (newData, oldData) =>
                //         new Promise(resolve => {
                //             if (oldData) {
                //                 firebase
                //                     .reserve(newData.uid)
                //                     .set({ ...newData });
                //                 resolve();
                //             }
                //         }),
                //     onRowDelete: oldData =>
                //         new Promise(resolve => {
                //             firebase.reserve(oldData.uid).remove();
                //             resolve();
                //         }),
                // }}
            />
        </div>
    );
};

const Delivered = withFirebase(ReservationBase);
const condition = authUser =>
    authUser &&
    authUser.roles &&
    (authUser.roles[ADMIN] === ADMIN || authUser.roles[WS] === WS);

export default compose(
    withAuthorization(condition),
    withEmailVerification,
    withFirebase,
)(DeliveredComponent);

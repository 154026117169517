import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    widgetRoot: {
        boxShadow: theme.customShadows.widget,
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden",
        padding: 20,
        marginLeft: 30,
        minWidth: 200,
    },
}));

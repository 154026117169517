import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";


import { compose } from 'recompose';
import { withAuthorization, AuthUserContext } from "../../Session";
import { withFirebase } from "../../components/Firebase";
import useStyles from "./styles";

const ADMIN = "ADMIN";
const WS = "WS";

const Reserve = () => {
    var classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h3" className={classes.title}>
                Workshop
            </Typography>
            <ReserveForm />
        </React.Fragment>
    );
};

function ReserveFormBase(props) {
    var classes = useStyles();

    const [profile, setProfile] = React.useState("");
    var [isLoading, setIsLoading] = useState(false);
    var [profiles, setProfiles] = useState([]);
    var [surfaces, setSurfaces] = useState([]);
    var [surface, setSurface] = React.useState("");
    var [locations, setLocations] = useState([]);
    var [location, setLocation] = useState("");
    var [length, setLength] = useState(0);
    var [count, setCount] = useState(0);
    var [projectNumber, setProjectNumber] = useState("");
    var [comment, setComment] = useState("");

    useEffect(() => {
        onListenForProfiles();
        onListenForSurfaces();
        onListenForLocations();
    }, []);

    const handleChangeSurface = event => {
        setSurface(event.target.value);
    };

    const handleChangeLocation = event => {
        setLocation(event.target.value);
    };

    const handleChangeProfile = event => {
        setProfile(event.target.value);
    };

    const handleChangeLength = event => {
        setLength(event.target.value);
    };

    const handleChangeCount = event => {
        setCount(event.target.value);
    };

    const handleChangeProjectNumber = event => {
        setProjectNumber(event.target.value);
    };

    const handleChangeComment = event => {
        setComment(event.target.value);
    };

    const getProfiles = () => {
        return profiles.map(profile => {
            return (
                <MenuItem key={profile.uid} value={profile.uid}>
                    {profile.text}
                </MenuItem>
            );
        });
    };

    const getSurfaces = () => {
        return surfaces.map(surface => {
            return (
                <MenuItem key={surface.uid} value={surface.uid}>
                    {surface.text}
                </MenuItem>
            );
        });
    };

    const getLocations = () => {
        return locations.map(location => {
            return (
                <MenuItem key={location.uid} value={location.uid}>
                    {location.text}
                </MenuItem>
            );
        });
    };

    const onListenForProfiles = () => {
        setIsLoading(true);

        props.firebase
            .profiles()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const profileObject = snapshot.val();
                if (profileObject) {
                    const profileList = Object.keys(profileObject).map(key => ({
                        ...profileObject[key],
                        uid: key,
                    }));

                    setIsLoading(false);
                    setProfiles(profileList);
                } else {
                    setIsLoading(false);
                    setProfiles(profiles);
                }
            });
    };

    const onListenForSurfaces = () => {
        setIsLoading(true);

        props.firebase
            .surfaces()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const surfaceObject = snapshot.val();
                if (surfaceObject) {
                    const surfaceList = Object.keys(surfaceObject).map(key => ({
                        ...surfaceObject[key],
                        uid: key,
                    }));

                    setIsLoading(false);
                    setSurfaces(surfaceList);
                } else {
                    setIsLoading(false);
                    setSurfaces(surfaces);
                }
            });
    };

    const onListenForLocations = () => {
        setIsLoading(true);

        props.firebase
            .locations()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const locationObject = snapshot.val();
                if (locationObject) {
                    const locationList = Object.keys(locationObject).map(
                        key => ({
                            ...locationObject[key],
                            uid: key,
                        }),
                    );

                    setIsLoading(false);
                    setLocations(locationList);
                } else {
                    setIsLoading(false);
                    setLocations(locations);
                }
            });
    };
    const onCreateItem = (event, authUser) => {
        setIsLoading(true);

        props.firebase.items().push({
            userId: authUser.uid,
            profile: profile,
            length: length,
            count: count,
            surface: surface,
            projectNumber: projectNumber,
            location: location,
            comment: comment,
            createdAt: props.firebase.serverValue.TIMESTAMP,
        });

        onCancleSubmit();

        event.preventDefault();
    };

    const onCancleSubmit = () => {
        setIsLoading(false);
        setProfile("");
        setCount(0);
        setSurface("");
        setLocation("");
        setLength(0);
        setProjectNumber("");
        setComment("");
    };

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <form
                    className={classes.rootForm}
                    noValidate
                    autoComplete="off"
                    onSubmit={event => onCreateItem(event, authUser)}
                >
                    <div className={classes.workshop}>
                        <div className={classes.workshopRow}>
                            <div className={classes.formControlDiv}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">
                                        Profil
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={profile}
                                        onChange={handleChangeProfile}
                                        className={classes.selectForm}
                                    >
                                        {getProfiles()}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className={classes.workshopSecondRow}>
                                <TextField
                                    label="Lengde i mm"
                                    type="number"
                                    onChange={handleChangeLength}
                                />
                            </div>
                        </div>
                        <div className={classes.workshopRow}>
                            <div>
                                <TextField
                                    label="Antall"
                                    type="number"
                                    onChange={handleChangeCount}
                                />
                            </div>
                            <div className={classes.workshopSecondRow}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">
                                        Overflate
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={surface}
                                        onChange={handleChangeSurface}
                                        className={classes.selectForm}
                                    >
                                        {getSurfaces()}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={classes.workshopRow}>
                            <div>
                                <TextField
                                    id="standard-basic"
                                    label="Proskjektnr"
                                    onChange={handleChangeProjectNumber}
                                />
                            </div>
                            <div className={classes.workshopSecondRow}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label">
                                        Lokasjon
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={location}
                                        onChange={handleChangeLocation}
                                        className={classes.selectForm}
                                    >
                                        {getLocations()}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className={classes.workshopRow}>
                            <TextareaAutosize
                                aria-label="minimum height"
                                value={comment}
                                rowsMin={15}
                                onChange={handleChangeComment}
                            />
                        </div>
                        <div className={classes.workshopRow}>
                            <div>
                                {" "}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                >
                                    {" "}
                                    Lagre{" "}
                                </Button>
                            </div>
                            <div className={classes.workshopSecondRow}>
                                {" "}
                                <Button
                                    variant="contained"
                                    onClick={onCancleSubmit}
                                >
                                    Avbryt
                                </Button>{" "}
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </AuthUserContext.Consumer>
    );
}

const ReserveForm = withFirebase(ReserveFormBase);
const condition = authUser =>
    authUser && (authUser.roles[ADMIN] === ADMIN || authUser.roles[WS] === WS);

export default compose(
    withAuthorization(condition),
    withFirebase,
  )(Reserve);

import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "@material-ui/core";
import useStyles from "./styles";

const ReserveDialog = ({
    item,
    open,
    onClose,
    onReserve,
    profiles,
    surfaces,
    locations,
    reserves,
    firebase,
}) => {
    const classes = useStyles();

    const defaultProfile = profiles.find(pro => pro.uid === item.profile);
    const defaultLocation = locations.find(loc => loc.uid === item.location);
    const defaultSurface = surfaces.find(s => s.uid === item.surface);

    const [isLoading, setIsLoading] = useState(false);
    const [profile, setProfile] = React.useState(
        defaultProfile ? defaultProfile.uid : "",
    );
    const [projectNumber, setProjectNumber] = React.useState("");
    const [count, setCount] = React.useState(item.count);
    const [lengthInMM, setLengthInMM] = React.useState(item.length);
    const [location, setLocation] = useState(
        defaultLocation ? defaultLocation.uid : "",
    );
    const [comment, setComment] = useState("");
    const [surface, setSurface] = React.useState(
        defaultSurface ? defaultSurface.uid : "",
    );
    const [msgMaxAllowedError, setMaxLengthError] = React.useState(false);
    const [msgMaxCountError, setMaxCountError] = React.useState(false);

    let maxCallback = (acc, cur) => (acc += parseInt(cur, 10));

    const getMaxPossibleLength = () => {
        return item.length * item.count;
    };

    const isCountGreaterThanOne = () => {
        return item.count > 1;
    };

    const getProfiles = () => {
        return profiles.map(profile => {
            return (
                <MenuItem key={profile.uid} value={profile.uid}>
                    {profile.name}
                </MenuItem>
            );
        });
    };

    const getSurfaces = () => {
        return surfaces.map(surface => {
            return (
                <MenuItem key={surface.uid} value={surface.uid}>
                    {surface.text}
                </MenuItem>
            );
        });
    };

    const getLocations = () => {
        return locations.map(location => {
            return (
                <MenuItem key={location.uid} value={location.uid}>
                    {location.text}
                </MenuItem>
            );
        });
    };

    const handleChangeProfile = event => {
        setProfile(event.target.value);
    };

    const handleChangeInLength = event => {
        if (event.target) {
            const value = event.target.value;
            const maxAllowedValue = getMaxPossibleLength();
            if (value > maxAllowedValue) {
                setMaxLengthError(true);
            } else {
                if (!isCountGreaterThanOne()) {
                    setLengthInMM(event.target.value);
                }
            }
        }
    };

    const handleChangeCount = event => {
        const maxAllowedCount = item.count;
        if (parseInt(event.target.value, 10) > maxAllowedCount) {
            setMaxCountError(true);
        } else {
            setCount(event.target.value);
            setMaxCountError(false);
        }
    };

    const handleChangeSurface = event => {
        setSurface(event.target.value);
    };

    const handleChangeLocation = event => {
        setLocation(event.target.value);
    };

    const handleChangeComment = event => {
        setComment(event.target.value);
    };

    const handleChangeProjectNumber = event => {
        setProjectNumber(event.target.value);
    };

    const onSubmitReservation = () => {
        const data = {
            projectNumber,
            profile,
            lengthInMM,
            count,
            surface,
            location,
            comment,
        };
        onReserve(data);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Reservasjon"}</DialogTitle>
            <DialogContent>
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <TextField
                        id="standard-basic"
                        label="Proskjektnr"
                        onChange={handleChangeProjectNumber}
                        value={projectNumber}
                    />
                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                            Profil
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={profile}
                            onChange={handleChangeProfile}
                            className={classes.selectForm}
                            fullWidth
                            disabled
                        >
                            {getProfiles()}
                        </Select>
                    </FormControl>
                </div>

                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <TextField
                        autoFocus
                        margin="dense"
                        id="length"
                        label="Lengde i mm"
                        type="number"
                        value={lengthInMM}
                        onChange={handleChangeInLength}
                        style={{ paddingRight: 40 }}
                        fullWidth
                        disabled={count > 1 ? true : false}
                        inputProps={{ min: "1", max: getMaxPossibleLength() }}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="count"
                        label="Antall"
                        type="number"
                        value={count}
                        onChange={handleChangeCount}
                        fullWidth
                        disabled={true}
                    />
                </div>
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <FormControl
                        className={classes.formControl}
                        style={{ paddingRight: 20 }}
                    >
                        <InputLabel id="demo-simple-select-label">
                            Overflate
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={surface}
                            onChange={handleChangeSurface}
                            className={classes.selectForm}
                            disabled
                        >
                            {getSurfaces()}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                            Lokasjon
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={location}
                            onChange={handleChangeLocation}
                            className={classes.selectForm}
                            fullWidth
                            disabled
                        >
                            {getLocations()}
                        </Select>
                    </FormControl>
                </div>

                <TextField
                    id="standard-basic"
                    label="Kommentar"
                    onChange={handleChangeComment}
                    value={comment}
                    multiline
                    fullWidth
                />

                {msgMaxAllowedError && (
                    <div style={{ color: "red", paddingTop: 20 }}>
                        {" "}
                        Max tillat reservasjon er {getMaxPossibleLength()}{" "}
                    </div>
                )}
                {msgMaxCountError && (
                    <div style={{ color: "red", paddingTop: 20 }}>
                        {" "}
                        Max tillat antall er {item.count}{" "}
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Avbryt
                </Button>
                <Button
                    onClick={onSubmitReservation}
                    color="primary"
                    autoFocus
                    disabled={count > item.count}
                >
                    Reserve
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReserveDialog;

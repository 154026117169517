import app from "firebase/app";
import "firebase/auth";
import "firebase/database";


const devConfig = {
    apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
    databaseURL: process.env.REACT_APP_DEV_DATABASE_URL,
    projectId: process.env.REACT_APP_DEV_PROJECT_ID,
    storageBucket: process.env.REACT_APP_DEV_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_DEV_MESSAGING_SENDER_ID,
};

const prodConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const config = prodConfig;

class Firebase {
    constructor() {

        app.initializeApp(config);

        /* Helper */
        this.serverValue = app.database.ServerValue;

        this.auth = app.auth();
        this.db = app.database();

        this.googleProvider = new app.auth.GoogleAuthProvider();
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    doSendEmailVerification = () =>
        this.auth.currentUser.sendEmailVerification({
            url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
        });

    doSendPasswordResetEmail = email => {
        this.auth.sendPasswordResetEmail(email);
    };

    doFetchSignInMethodsForEmail = email => {
        this.auth.fetchSignInMethodsForEmail(email);
    };

    // *** Merge Auth and DB User API *** //
    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.user(authUser.uid)
                    .once("value")
                    .then(snapshot => {
                        const dbUser = snapshot.val();

                        // default empty roles
                        if (dbUser && !dbUser.roles) {
                            dbUser.roles = {PL: "PL"};
                        }

                        // merge auth and db user
                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            ...dbUser,
                        };
                        next(authUser);
                    });
            } else {
                fallback();
            }
        });

    // *** User API ***
    user = uid => this.db.ref(`users/${uid}`);

    users = () => this.db.ref("users");

    // *** Message API ***
    message = uid => this.db.ref(`messages/${uid}`);

    messages = () => this.db.ref("messages");

    // *** Surface API ***
    surface = uid => this.db.ref(`surfaces/${uid}`);

    surfaces = () => this.db.ref("surfaces");

    // **** Profile API

    profile = uid => this.db.ref(`profiles/${uid}`);

    profiles = () => this.db.ref(`profiles`);

    // **** Location API

    location = uid => this.db.ref(`locations/${uid}`);

    locations = () => this.db.ref(`locations`);

    // **** Item API

    item = uid => this.db.ref(`items/${uid}`);

    items = () => this.db.ref(`items`);

    reserve = uid => this.db.ref(`reserve/${uid}`);

    reserves = () => this.db.ref(`reserve`);

    // *** project progress API

    progress = uid => this.db.ref(`progresses/${uid}`);

    progresses = () => this.db.ref(`progresses`);

    progressDetail = uid => this.db.ref(`progressDetails/${uid}`);

    progressDetails = () => this.db.ref(`progressDetails`);

    // progress Log API
    progressLogs = uid => this.db.ref(`progressLogs/${uid}`);
    progressLogs = () => this.db.ref(`progressLogs`);
}
export default Firebase;

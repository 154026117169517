import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  rootForm: {
    width: "50%",
    marginLeft : "25%"
  },
  title: {
    fontWeight: 100,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  myForm : {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "30px"
  },
  input : {
    flex: "2 0 0",
    padding: "12px 20px",
    margin: "8px 8px 0 0",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box"
  },
  mybutton : {
    flex: "1 0 0",
    margin: "8px 0 0 0",
  },
  error : {
    color: "red"
  },
  workshop: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: "400px"
  },
  workshopRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingTop: "50px"
  }, 
  workshopSecondRow: {
    //paddingLeft: "50px"
  },
  formControl: {
    //margin: "10px 0 10px 0"
  },
  formControlDiv: {
    maxWidth: "50px"
  },
  selectForm: {
    minWidth: 170
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

}));

import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Status from "./status";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 160,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const utPris = 565;

const NewProjectForm = ({ open, onClose, authUser, firebase, users }) => {
    const [projectName, setProjectName] = useState("");
    const [projectNr, setProjectNr] = useState("");
    const [projectLeader, setProjectLeader] = useState("");
    const [description, setDescription] = useState("");
    const [staalWeight, setStaalWeight] = useState(0);
    const [pricePerKg, setPricePerKg] = useState(0);
    const [workshopHours, setWorkshopHours] = useState(0);
    const [drawingReadyDate, setDrawingReadyDate] = useState("");
    const [staalDeliveryDate, setStaalDeliveryDate] = useState("");
    const [staalExpireDate, setStaalExpireDate] = useState("");
    const [status, setStatus] = useState("notBegin");
    const [utpris, setUtpris] = useState(utPris);
    const classes = useStyles();

    const handleChange = e => {
        switch (e.currentTarget.name) {
            case "project":
                setProjectName(e.target.value);
                break;
            case "projectnr":
                setProjectNr(e.target.value);
                break;
            case "projectLeader":
                setProjectLeader(e.target.value);
                break;

            case "staalWeight":
                setStaalWeight(e.target.value);
                break;
            case "pricePerKg":
                setPricePerKg(e.target.value);
                break;
            case "workshopHours":
                setWorkshopHours(e.target.value);
                break;
            case "drawingReadyDate":
                setDrawingReadyDate(e.target.value);
                break;
            case "staalDeliveryDate":
                setStaalDeliveryDate(e.target.value);
                break;
            case "staalExpireDate":
                setStaalExpireDate(e.target.value);
                break;
            case "status":
                setStatus(e.target.value);
                break;
            case "description":
                setDescription(e.target.value);
                break;
            case "utpris":
                setUtpris(e.target.value);
                break;
            default:
                throw new Error("not found");
        }
    };

    const handleChangeSelect = e => {
        setStatus(e.target.value);
    };

    const handleChangeProjectLeader = e => {
        setProjectLeader(e.target.value);
    }

    const handleFormSubmit = event => {
        event.preventDefault();

        const newProgressKey =
            firebase.progresses().push({
                createdBy: authUser.uid,
                projectName: projectName,
                projectNr: projectNr,
                projectLeader: projectLeader,
                staalWeight: staalWeight,
                pricePerKg: pricePerKg,
                workshopHours: workshopHours,
                drawingReadyDate: drawingReadyDate,
                staalDeliveryDate: staalDeliveryDate,
                staalExpireDate: staalExpireDate,
                status: status,
                description: description,
                utpris: utpris,
                createdAt: firebase.serverValue.TIMESTAMP,
                updatedAt: firebase.serverValue.TIMESTAMP,
        }).key;

            if (newProgressKey ) {
                firebase.progressLogs().push({
                    progressId : newProgressKey,
                    staalWeight: staalWeight,
                    pricePerKg: pricePerKg,
                    workshopHours: workshopHours,
                    status: status,
                    createdAt: firebase.serverValue.TIMESTAMP
                });
            }



        onClose();
    };

    const getStatusLookup = () => {
        const statusItem = [];
        for (const key in Status) {
            statusItem.push(
                <MenuItem value={key} key={key}>
                    {Status[key]}
                </MenuItem>,
            );
        }
        return statusItem;
    };

    const getProjectLeaders = () => {
       const projectLeaders = [];
       users.forEach(user => {
            projectLeaders.push(
                <MenuItem value={user.uid} key={user.username}>
                    {user.username}
                </MenuItem>
            )
        });
        return projectLeaders;
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Prosjekt"}</DialogTitle>
            <DialogContent>
                <form onSubmit={event => handleFormSubmit(event, authUser)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="project"
                                label="Prosjekt"
                                type="text"
                                value={projectName}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="projectnr"
                                label="Prosjektnr"
                                type="text"
                                value={projectNr}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="project-leader-select-label">PL</InputLabel>
                            <Select
                              labelId="project-leader-label"
                              id="project-leader-select"
                              value={projectLeader}
                              onChange={e => handleChangeProjectLeader(e)}
                            >
                                {getProjectLeaders()}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="staalWeight"
                                label="stål vekt(kg)"
                                type="number"
                                value={staalWeight}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="workshopHours"
                                label="Timer i verksted"
                                type="number"
                                value={workshopHours}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="drawingReadyDate"
                                label="Tegninger klar"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={drawingReadyDate}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="staalDeliveryDate"
                                label="Stål leveranse"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                value={staalDeliveryDate}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="staalExpireDate"
                                label="Stål ut(frist)"
                                type="date"
                                value={staalExpireDate || ""}
                                onChange={e => handleChange(e)}
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="demo-simple-select-label">
                                    Status
                                </InputLabel>
                                <Select
                                    labelId="Status"
                                    id="status"
                                    value={status}
                                    name="status"
                                    onChange={e => handleChangeSelect(e)}
                                >
                                    {getStatusLookup()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="utpris"
                                label="Utprise"
                                type="number"
                                defaultValue={utPris}
                                value={utpris}
                                disabled={true}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="description"
                                label="Beskrivelse"
                                type="textarea"
                                value={description}
                                onChange={e => handleChange(e)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={4}
                        style={{ flexDirection: "row-reverse" }}
                    >
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                onClick={onClose}
                                color="secondary"
                            >
                                Avbryt
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Lagre
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default NewProjectForm;

import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import { withFirebase } from "../../components/Firebase";
import { Axis, Chart, Geom, getTheme, Interval, Legend, Tooltip } from "bizcharts";
import MaterialTable from "material-table";
import { tableIcons } from "../../components/TableIcons/TableIcons";

const IDEAL_STEAL_PRODUCTION_IN_TON = 1500; // process.env.REACT_APP_IDEAL_STEAL_PRODUCTION_IN_TON;

//const Factor = 5;

function Dashboard(props) {
    const [items, setItems] = useState([]);
    const [reserves, setReserves] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [progress, setProgress] = useState([]);
    const [progressLogs, setProgressLogs] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            await onListenForItems();
            await onListenForProgress();
            await onListenForProgressLogs();
        };
        fetchData();
    }, []);

    const onListenForItems = async () => {
        props.firebase
            .profiles()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const profileObject = snapshot.val();
                if (profileObject) {
                    const profileList = Object.keys(profileObject).map(key => ({
                        ...profileObject[key],
                        uid: key,
                    }));

                    setProfiles(profileList);
                } else {
                    setProfiles(profiles);
                }
            });

        props.firebase
            .items()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const itemObject = snapshot.val();
                if (itemObject) {
                    const itemList = Object.keys(itemObject).map(key => ({
                        ...itemObject[key],
                        uid: key,
                    }));

                    setItems(itemList);
                } else {
                    setItems(items);
                }
            });

        props.firebase
            .reserves()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const surfaceObject = snapshot.val();
                if (surfaceObject) {
                    const surfaceList = Object.keys(surfaceObject).map(key => ({
                        ...surfaceObject[key],
                        uid: key,
                    }));

                    setReserves(surfaceList);
                } else {
                    setReserves(reserves);
                }
            });
    };

    const onListenForProgress = async () => {
        props.firebase
            .progresses()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const progressObject = snapshot.val();
                if (progressObject) {
                    const progressList = Object.keys(progressObject).map(
                        key => ({
                            ...progressObject[key],
                            uid: key,
                        }),
                    );

                    setProgress(progressList);
                } else {
                    setProgress(progress);
                }
            });
    };

    const onListenForProgressLogs = async () => {
        props.firebase
            .progressLogs()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const progressLogsObject = snapshot.val();
                if (progressLogsObject) {
                    const progressLogsList = Object.keys(progressLogsObject).map(
                        key => ({
                            ...progressLogsObject[key],
                            uid: key,
                        }),
                    );

                    setProgressLogs(progressLogsList);
                } else {
                    setProgressLogs(progressLogs);
                }
            });
    };

    const getWeekNumberFromDateTime = period => {
        const createdDate = new Date(period);
        return moment(createdDate).week();
    };

    const getStatusPercentage = status => {
        switch (status) {
            case "delivered":
            case "doneFromWorkshope":
            case "oneHundred":
                return 100;
            case "ten":
                return 10;
            case "twenty":
                return 20;
            case "thirty":
                return 30;
            case "forty":
                return 40;
            case "fifty":
                return 50;
            case "sixty":
                return 60;
            case "seventy":
                return 70;
            case "eighty":
                return 80;
            case "nighty":
                return 90;
            default:
                return 0;
        }
    };

    const weeksInYear = (year) => {
        return Math.max(
            moment(new Date(year, 11, 31)).isoWeek(),
            moment(new Date(year, 11, 31 - 7)).isoWeek(),
        );
    };

    const currentWeekNumber = () => {
        return moment().format("w");
    };

    const getTotalItemsInStore = () => {
        let sumOfItemsInStore = 0;
        let reservedItems = 0;

        if (!profiles) {
            return sumOfItemsInStore;
        }

        reserves
            .filter(reserve => reserve.approved !== 2)
            .map(item => {
                const length = parseInt(item.length, 10);
                const count = parseInt(item.count, 10);
                const lengthInMeter = (length * count) / 1000;
                const profile = profiles.find(pro => pro.uid === item.profile);

                if (profile) {
                    const weightInTon =
                        (lengthInMeter * parseFloat(profile.weight)) / 1000;
                    reservedItems += weightInTon;
                }
            });

        items.map(item => {
            const profile = profiles.find(pro => pro.uid === item.profile);
            const length = parseInt(item.length, 10)
                ? parseInt(item.length, 10)
                : 0;
            const count = parseInt(item.count, 10)
                ? parseInt(item.count, 10)
                : 0;
            const lengthInMeter = (length * count) / 1000;

            if (profile) {
                const weightInTon =
                    (lengthInMeter * parseFloat(profile.weight)) / 1000;
                sumOfItemsInStore += weightInTon;
            }
        });
        return sumOfItemsInStore ; 
    };

    const currentYear = () => {
        return moment().year();
    };


    const groupBy = (objectArray, property, year) => {
      return objectArray.reduce(function (acc, obj) {
        var key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }

          if (moment(obj.createdAt).year() === year) {
              acc[key].push(obj);
          }
        return acc;
      }, {});
    };


    
    const getItemsRegistredSum = (year) => {
        let result = [];
        let i;

        if (!progress) {
            return result;
        }

        for (i = 1; i <= weeksInYear(year); i++) {
            result[i] = 0;
        }

        const groupedByProgressId = groupBy(progressLogs, "progressId", year);

         const dd = Object.keys(groupedByProgressId).map((key, index) => {

             const items = groupedByProgressId[key];

             return getItems(items, year);

            });

        Object.keys(dd).map((key, index) =>{
            const item = dd[key];

            return item.map((m,ix) => {

                
                return result[ix] += m;
            });

        });



       return result.filter(f => f == 0 || f);
    };
  
    const getItems = (iit, year) => {
            let x = [];
            let i;

            for (i = 1; i <= weeksInYear(year); i++) {
                x[i] = 0;
            }
            
            const sortedByCreatedDate = iit.sort((a, b) => b.createdAt - a.createdAt);

            sortedByCreatedDate
            .filter(i =>  moment(i.createdAt).year() === year)
            .map(i => {
                const weekNumber = getWeekNumberFromDateTime(i.createdAt);

                
                if (x[weekNumber] !== 0 && x[weekNumber]) {
                    return null;

                } else {
                    const percentageCompleted = getStatusPercentage(i.status);
                

                    const weight = parseInt(i.staalWeight, 10);

                    const completedWeightInKg =
                        (percentageCompleted * weight) / 100;


                     // Removed Factor from here
                    x[weekNumber] = completedWeightInKg / 1000;  // convert to ton

                } 
            }).filter(f => f);


        return x;
    }

    const getItemsOut = () => {
        let result = [];
        let i;

        if (!profiles) {
            return result;
        }

        for (i = 1; i <= weeksInYear(moment().year()); i++) {
            result[i] = 0;
        }

        reserves

            .filter(
                reserve =>
                    reserve.approved !== 2 &&
                    moment(reserve.createdAt).year() === currentYear(),
            )
            .map(item => {
                const weekNumber = getWeekNumberFromDateTime(item.createdAt);
                const length = parseInt(item.length, 10);
                const count = parseInt(item.count, 10);
                const lengthInMeter = (length * count) / 1000;
                const profile = profiles.find(pro => pro.uid === item.profile);

                if (profile) {
                    const weightInTon =
                        (lengthInMeter * parseFloat(profile.weight)) / 1000;
                    result[weekNumber] += weightInTon;
                } else {
                    result[weekNumber] += 0;
                }
            });
        return result;
    };


    const getItemsOnStore = () => {
        let result = [];
        let i;

        if (!profiles) {
            return result;
        }

        for (i = 1; i <= weeksInYear(moment().year()); i++) {
            result[i] = 0;
        }

        items
        .filter(i => moment(i.createdAt).year() === currentYear())
            .map(item => {
                const profile = profiles.find(pro => pro.uid === item.profile);
                const weekNumber = getWeekNumberFromDateTime(item.createdAt);
                const length = parseInt(item.length, 10) ? parseInt(item.length, 10) : 0;
                const count = parseInt(item.count, 10) ? parseInt(item.count, 10) : 0;
                const lengthInMeter = (length * count) / 1000;

                if (profile) {
                    const weightInTon =
                        (lengthInMeter * parseFloat(profile.weight)) / 1000;
                    result[weekNumber] += weightInTon;
                } else {
                    result[weekNumber] += 0;
                }
            });
        return result;
    };


    const registered =  (year) => getItemsRegistredSum(year);


    const reservedOut = getItemsOut();
    const totalSum = getTotalItemsInStore().toFixed(2);

    const cols = {
        month: {
            range: [0, 1],
        },
    };

    const colors = getTheme().colors10;

    const weeksOfAYear = ( year ) => {
        let weeks = [];
        let DataInWeek = [];
        let sum = 0;

        let result = [];

          // Iterate over the elements of the array
          for (let i = 0; i < registered(year).length; i++) {
            weeks.push(i);
          }

          DataInWeek = cumulativeSum(registered(year));

        return {
            weeks,
            DataInWeek,
        };
    };


    const cumulativeSum = (x) => {
      let result = [];

      // Initialize the cumulative sum to 0
      let sum = 0;

      // Iterate over the elements of the array
      for (let i = 0; i < x.length; i++) {
        // Add the current element to the cumulative sum
        sum += x[i];
        // Add the cumulative sum to the result array
        result.push(sum);
      }

      return result;
    };

    const weeks = weeksOfAYear(moment().year());

    const lastYearWeeks = weeksOfAYear( moment().year() - 1 );

    const ideal = weeks.weeks.map((m, i) => {
        return {
            week: i + 1,
            type: "Ideelt",
            ton:
                (i) *
                parseFloat(
                    IDEAL_STEAL_PRODUCTION_IN_TON / weeksInYear(moment().year()),
                ).toFixed(2),
        };
    });

     const realDataLastYear = lastYearWeeks.weeks.map((m, i) => {
        return {
            week: m + 1,
            type: (moment().year() - 1).toString(),
            ton: parseFloat(lastYearWeeks.DataInWeek[m]).toFixed(2)
        };
    });


   /* const realData = weeks.weeks.map((m, i) => {
        return {
            week: m + 1,
            type: "Real(" + moment().year().toString() + ")",
            ton: parseFloat(weeks.DataInWeek[m]).toFixed(2),
        };
    });*/

    const allData = ideal.concat(realDataLastYear); //ideal.concat(realData).concat(realDataLastYear);



    const pr = weeks.DataInWeek[currentWeekNumber()]
        ? weeks.DataInWeek[currentWeekNumber()].toFixed(2)
        : 0;

    const itemsDeliveryingThisWeek = () => {
       const  weekNumber = currentWeekNumber();
       const progressData = progress.filter(p => moment(p.createdAt).year() === currentYear() && moment(p.staalDeliveryDate).week() == weekNumber);
       return progressData ;
    };

    const itemsToBeDeliveredThisWeek = () => {
       const  weekNumber = currentWeekNumber();
       const progressData = progress.filter(p => moment(p.createdAt).year() === currentYear() && moment(p.staalExpireDate).week() == weekNumber);
       return progressData ;
    };

    const onStoreAndReservedOutData = newDatat(getItemsOnStore(items), reservedOut);

    // Items that will be delivered to Staalteknik
    const itemsDeliveryingThisWeekData = itemsDeliveryingThisWeek();
    const columnsForDelivering = [
            { title: "Prosjektnr", field: "projectNr" },
            { title: "Dato", field: "staalDeliveryDate" }
        ];


    // Items that will be sent out
    const itemsToBeDeliveredThisWeekData = itemsToBeDeliveredThisWeek();
    const columnsToBeDelivered = [
            { title: "Prosjektnr", field: "projectNr" },
            { title: "Sist dato", field: "staalExpireDate" }
        ];
    
    const classes = useStyles();


    return (
        <>
            <Typography variant="h3" weight="medium">
                Rapport
            </Typography>
              <Grid cotaniner style={{paddingTop: 40}}>
                <Grid item xs={12}>
                    <ColumChart
                        registered={registered}
                        reservedOut={reservedOut}
                        total={totalSum}
                        producedThisYear={pr}
                        weekNumber={currentWeekNumber()}
                        itemsDeliveryingThisWeek={itemsDeliveryingThisWeek()}
                    />
                </Grid>
           <Grid container style={{paddingTop: 40}} spacing={8}>
                <Grid item xs={3}>
                        <MaterialTable
                         icons={tableIcons}
                         title="Stål leveranse"
                         columns={columnsForDelivering}
                          data={itemsDeliveryingThisWeekData}
                         />
                </Grid>
                <Grid item xs={8}>
                  <Chart height={400} padding="auto" data={onStoreAndReservedOutData} autoFit>
                    <Interval
                        adjust={[
                            {
                                type: "dodge",
                                marginRatio: 0,
                            },
                        ]}
                        color="name"
                        position="period*value"
                    />
                    <Legend name="name" position="top" />
                    <Tooltip shared />
                 </Chart>
              </Grid>
        </Grid>
        <Grid container style={{paddingTop: 40}} spacing={8}>
            <Grid item xs={3}>
                        <MaterialTable
                         icons={tableIcons}
                         title="Stål ut(frist)"
                         columns={columnsForDelivering}
                          data={itemsToBeDeliveredThisWeekData}
                         />
                </Grid> 
            <Grid item xs={8}>
                    <Chart
                        height={500}
                        data={allData}
                        scale={cols}
                        padding="auto"
                        autoFit
                        onAxisLabelClick={console.log}
                    >
                        <Legend
                            itemName={{
                                style: item => {
                                    return {
                                        fill:
                                            item.name === "Ideal"
                                                ? colors[0] 
                                                : colors[1],
                                    };
                                },
                            }}
                        />
                        <Axis
                            name="week"
                            visible={true}
                            label={{
                                formatter: val => `Uke-${val}`,
                            }}
                        />
                        <Axis
                            visible={true}
                            name="Ton"
                            label={{
                                formatter: val => `${val}`,
                            }}
                        />
                        <Tooltip
                            shared
                            showCrosshairs
                            region={null}
                            g2-tooltip-list-item={{display: "flex"}}
                        />

                        <Geom
                            type="point"
                            position="week*ton"
                            size={4}
                            shape={"circle"}
                            color={"type"}
                            style={{
                                stroke: "#fff",
                                lineWidth: 1,
                            }}
                        />
                        <Geom
                            type="line"
                            position="week*ton"
                            size={2}
                            color={"type"}
                            shape={"smooth"}
                        />
                    </Chart>
                </Grid>


        </Grid>

               
            </Grid>
        </>
    );
}

const currentWeekNumber = () => {
    return moment().week();
};

const newDatat = (registered, reservedOut) => {
    let data = [];

    for (let i = 1; i <= currentWeekNumber(); i++) {
        data.push({
            name: "På lager",
            period: "uke-" + i,
            value: registered[i],
        });
    }

    for (let i = 1; i <= currentWeekNumber(); i++) {
        data.push({
            name: "Reservert",
            period: "uke-" + i,
            value: reservedOut[i].toFixed(2),
        });
    }
    return data;
};

function ColumChart(props) {
    var classes = useStyles();

    return (
        <>
            <Grid container spacing={2}>
                      <Paper className={classes.paper}>
                            <Typography
                                variant="h5"
                                color="initial"
                                style={{paddingTop: 10, paddingLeft: 30}}
                            >
                                Totalt på lagre
                            </Typography>
                            <Typography
                                variant="h1"
                                color="primary"
                                style={{paddingTop: 40, paddingLeft: 30}}
                            >
                                {props.total} tonn
                            </Typography>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Typography
                                variant="h5"
                                color="initial"
                                style={{paddingTop: 10, paddingLeft: 30}}
                            >
                                {`Produsert i år (uke-${props.weekNumber}) `}
                            </Typography>
                            <Typography
                                variant="h1"
                                color="primary"
                                style={{paddingTop: 40, paddingLeft: 30}}
                            >
                                {props.producedThisYear  } tonn    
                            </Typography>
                        </Paper>
                        
            </Grid>
            
        </>
    );
}

export default withFirebase(Dashboard);

const LoginErrors =  (code)  => {
    switch(code) {
        case "auth/user-not-found": {
            return "Det finnes ingen bruker med denne e-post address"
        }
        default: {
            return "Fail oppstått: Kontakt Administrator"
        }
    }
}

export default LoginErrors;
import axios from 'axios'

const sendEmail = (rowData, subject, body, receiver) => {
    const data = JSON.stringify(
        {
            "to": receiver ? receiver : rowData.reservedBy,
            "from":"natnasolutions@gmail.com",
            "subject": subject,
            "item": rowData,
            "emailBody": body
        });

    const config = {
        method: 'post',
        url: process.env.REACT_APP_MAIL_URL,
        headers: { 
            'Content-Type': 'application/json', 
            'x-api-key': process.env.REACT_APP_MAIL_API_KEY
        },
        data : data
    };

    axios(config)
        .then(response => {
        console.log("email sent");
        }).catch(err => {
            console.log("failed to post", err);
        });
};

export default sendEmail;
import React, { useState, useEffect } from "react";
import { Button, Typography, CircularProgress } from "@material-ui/core";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import IconButton from "@material-ui/core/IconButton";

import {
    withAuthorization,
    AuthUserContext,
    withEmailVerification,
} from "../../Session";
import MaterialTable from "material-table";
import { withFirebase } from "../../components/Firebase";
import { tableIcons } from "../../components/TableIcons/TableIcons";
import useStyles from "./styles";
import { compose } from "recompose";
import Menus from "./data.json";
import CustomMenuItem from "./customMenuItem";
import newItems from "../../data/newItems.json";
import ReserveDialog from "./reserveDialog";
import Tooltip from "@material-ui/core/Tooltip";

import {
    HEA,
    HEB,
    Likebenet_Vinkel,
    Ulikebenet_Vinkel,
    UNP,
    UPE,
    IPE,
    HUP_Kvadratiske,
    HUP_Rektangulaere,
    Runde,
} from "../../images";
import sendEmail from "../../mail/sendEmail";

const PL = "PL";
const ADMIN = "ADMIN";
const WS = "WS";
const APPROVED = 1;
const DISAPPROVED = 2;
const RESERVED = 0;
const MIN_ALLOWED_ITEM = 2000;

const WORKSHOP_EMAIL_ID = process.env.REACT_APP_WORKSHOP_EMAIL_ID;

const Item = ({ history }) => {
    var classes = useStyles();

    var [profile, setProfile] = useState("Structural");
    var [subProfile, setSubProfile] = useState("");

    const returnModule = img => {
        switch (img) {
            case "Likebenet":
                return Likebenet_Vinkel;
            case "Ulikebenet":
                return Ulikebenet_Vinkel;
            case "UNP":
                return UNP;
            case "UPE":
                return UPE;
            case "IPE":
                return IPE;
            case "Kvadratiske":
                return HUP_Kvadratiske;
            case "Rektangulære":
                return HUP_Rektangulaere;
            case "HEA":
                return HEA;
            case "HEB":
                return HEB;
            case "rør":
                return Runde;
            default:
                return HEA;
        }
    };

    const onAddNewItem = () => {
        history.push("/app/workshop");
    };

    const handleChangeProfile = e => {
        setProfile(e.target.value);
        setSubProfile(Menus[e.target.value][0].type);
    };

    const handleChangeSubProfile = e => {
        setSubProfile(e.target.value);
    };

    const handleOnClickProfile = type => {
        console.log("on click choice", type);
    };

    const handleOnClickSubProfile = type => {
        console.log("on click choice", type);
    };

    const defaultSubProfile = subProfile ? subProfile : Menus[profile][0].type;
    const image = returnModule(defaultSubProfile);

    return (
        <React.Fragment>
            <div style={{ paddingBottom: 30 }}>
                <div>
                    <Typography variant="h3" className={classes.title}>
                        Varelager
                    </Typography>
                </div>
            </div>

            <div className={classes.formControls}>
                <FormControl className={classes.formInputControl}>
                    <InputLabel id="demo-simple-select-label">
                        Velg profil
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={profile}
                        onChange={handleChangeProfile}
                    >
                        {Menus.profiles.map(pro => {
                            return (
                                <MenuItem value={pro.type} key={pro.id}>
                                    <CustomMenuItem
                                        profile={pro}
                                        clickHandler={handleOnClickProfile}
                                    />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>

                {profile && (
                    <FormControl className={classes.formInputControl}>
                        <InputLabel id="demo-simple-select-label">
                            Velg profiltype
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={defaultSubProfile}
                            onChange={handleChangeSubProfile}
                        >
                            {Menus[profile].map(str => {
                                return (
                                    <MenuItem
                                        value={str.startsWith}
                                        key={str.id}
                                    >
                                        <CustomMenuItem
                                            profile={str}
                                            clickHandler={
                                                handleOnClickSubProfile
                                            }
                                        />
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}
                <FormControl>
                    <img
                        src={image}
                        style={{ width: 90, height: 90, marginTop: 30 }}
                    />
                </FormControl>
            </div>

            <Items active={defaultSubProfile} />

            <div className={classes.btn_new_item}>
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={onAddNewItem}
                >
                    {" "}
                    Legg til ny
                </Button>
            </div>
        </React.Fragment>
    );
};

function ItemBase(props) {
    var classes = useStyles();

    var [isLoading, setIsLoading] = useState(false);
    var [items, setItems] = useState([]);
    var [profiles, setProfiles] = useState([]);
    var [locations, setLocations] = useState([]);
    var [surfaces, setSurfaces] = useState([]);
    var [reserves, setReserves] = useState([]);
    var [openDialog, setOpenDialog] = useState(false);
    var [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await onListenForItems();
            setIsLoading(false);
        };

        fetchData();
    }, []);

    const onListenForItems = async () => {
        props.firebase
            .items()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const itemObject = snapshot.val();
                if (itemObject) {
                    const itemList = Object.keys(itemObject).map(key => ({
                        ...itemObject[key],
                        uid: key,
                    }));

                    setIsLoading(false);
                    setItems(itemList);
                } else {
                    setIsLoading(false);
                    setItems(items);
                }
            });

        props.firebase
            .profiles()
            .orderByChild("createdAt")
            .once("value", snapshot => {
                const profileObject = snapshot.val();
                if (profileObject) {
                    const profileList = Object.keys(profileObject).map(key => ({
                        ...profileObject[key],
                        uid: key,
                    }));

                    setIsLoading(false);
                    setProfiles(profileList);
                } else {
                    setIsLoading(false);
                    setProfiles(profiles);
                }
            });

        props.firebase
            .locations()
            .orderByChild("createdAt")
            .once("value", snapshot => {
                const locationObject = snapshot.val();
                if (locationObject) {
                    const locationList = Object.keys(locationObject).map(
                        key => ({
                            ...locationObject[key],
                            uid: key,
                        }),
                    );

                    setIsLoading(false);
                    setLocations(locationList);
                } else {
                    setIsLoading(false);
                    setLocations(locations);
                }
            });

        props.firebase
            .surfaces()
            .orderByChild("createdAt")
            .once("value", snapshot => {
                const surfaceObject = snapshot.val();
                if (surfaceObject) {
                    const surfaceList = Object.keys(surfaceObject).map(key => ({
                        ...surfaceObject[key],
                        uid: key,
                    }));

                    setIsLoading(false);
                    setSurfaces(surfaceList);
                } else {
                    setIsLoading(false);
                    setSurfaces(surfaces);
                }
            });

        props.firebase
            .reserves()
            .orderByChild("createdAt")
            .once("value", snapshot => {
                const surfaceObject = snapshot.val();
                if (surfaceObject) {
                    const surfaceList = Object.keys(surfaceObject).map(key => ({
                        ...surfaceObject[key],
                        uid: key,
                    }));

                    setIsLoading(false);
                    setReserves(surfaceList);
                } else {
                    setIsLoading(false);
                    setReserves(reserves);
                }
            });
    };

    const onClickImport = (e, authUser) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("Importing");
        console.log(newItems);

        newItems.forEach(item => {
            const profile = profiles.find(
                profile => profile.name === item.Profile,
            );
            const localLocation = locations.find(
                location => location.text === item.Lokasjon,
            );
            const localSurface = surfaces.find(
                surface => surface.text === item.Overflate,
            );
            console.log("profile", profile);
            if (profile) {
                props.firebase.items().push({
                    userId: authUser.uid,
                    profile: profile.uid,
                    length: item["Lengde i mm"],
                    count: item.Antall,
                    surface: localSurface ? localSurface.uid : "",
                    projectNumber: item.Prosjektnr ? item.Prosjektnr : 0,
                    location: localLocation ? localLocation.uid : "",
                    comment: item.Kommentar ? item.Kommentar : "",
                    //chargenr: item.Chargenr,
                    createdAt: props.firebase.serverValue.TIMESTAMP,
                });
            } else {
                console.log("not found", item.Profil);
            }
        });
    };


    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                    <div>
                        <div>{isLoading && <CircularProgress size={26} />}</div>

                        {items ? (
                            <ItemList
                                items={items}
                                profiles={profiles}
                                locations={locations}
                                surfaces={surfaces}
                                reserves={reserves}
                                firebase={props.firebase}
                                setOpenDialog={setOpenDialog}
                                openDialog={openDialog}
                                setSelectedItem={setSelectedItem}
                                selectedItem={selectedItem}
                                active={props.active}
                                authUser={authUser}
                            />
                        ) : (
                            <div> There are no profiles yet </div>
                        )}
                        {props.item}
                    </div>

                    {/* <div className={classes.workshopRow}>
                        <div> <Button variant="contained" color="primary" onClick={event => onClickImport(event, authUser)}> Import </Button></div>
                    </div> */}
                </div>
            )}
        </AuthUserContext.Consumer>
    );
}

const ItemList = ({
    active,
    items,
    profiles,
    locations,
    surfaces,
    reserves,
    firebase,
    setSelectedItem,
    selectedItem,
    authUser,
    openDialog,
    setOpenDialog,
}) => {
    let maxCallback = (acc, cur) => (acc += parseInt(cur, 10));

    const getCurrentActualLengthOfItem = item => {
        const originalLength = parseInt(item.length, 10);
        return parseInt(item.count, 10) * originalLength ;
    };

    const getCurrentActualCount = item => {
        const originalCount = parseInt(item.count, 10);
        const result = originalCount;
        return result >= 1 ? result : 1;
    };

    const getHUPProfile = (profile, suffix) => {
        if (active === suffix) {
            return profile;
        }
    };

    const getAllOthers = (profile, prefix) => {
        if (active === prefix) {
            return profile;
        }
    };

    const getByType = profile => {
        const profileType = profile.type.split(" ");
        if (profileType && profileType.length > 1) {
            const prefix = profileType[0];
            const suffix = profileType[1];

            switch (prefix) {
                case "HUP":
                    return getHUPProfile(profile, suffix);
                default:
                    return getAllOthers(profile, prefix);
            }
        }
    };

    let itemList = [];

    items
        .sort((a, b) => b.createdAt - a.createdAt)
        .forEach((item, index) => {


            const location = locations.find(
                location => location.uid === item.location,
            );

            const profile = profiles.find(
                profile =>
                    profile.uid === item.profile &&
                    (profile.name.indexOf(active.substring(0, 3)) !== -1 ||
                        getByType(profile)),
            );

            const surface = surfaces.find(
                surface => surface.uid === item.surface,
            );

            const currentActualCount =
                parseInt(item.count, 10) > 1
                    ? getCurrentActualCount(item)
                    : item.count;

            const currentActualLength =
                currentActualCount > 1
                    ? item.length
                    : getCurrentActualLengthOfItem(item);

            if (profile && currentActualLength > MIN_ALLOWED_ITEM) {
                itemList.push({
                    uid: item.uid,
                    profile: profile.uid,
                    length: currentActualLength,
                    count: parseInt(currentActualCount, 10),
                    surface: surface && surface.uid,
                    projectNumber: item.projectNumber,
                    location: location && location.uid,
                });
            }
        });

    const closeDialog = () => {
        setOpenDialog(false);
    };

    const handleClose = () => {
        closeDialog();
    };

    const waitingForApprovalMessage = data => {
        return `
        <!DOCTYPE html>
        <html>
          <head></head>
          <body>
              <pre>
                <h4>Hei</h4>
                <p> Du har en ny vare klar for godkjenning </p>
              </pre>
          </body>
        </html>
      `;
    };

    const handleReserve = data => {
        firebase.reserves().push({
            reservedBy: authUser.uid,
            profile: data.profile,
            length: data.lengthInMM,
            count: data.count,
            surface: data.surface,
            projectNumber: data.projectNumber,
            location: data.location,
            comment: data.comment,
            itemId: selectedItem.uid,
            approved: RESERVED,
            createdAt: firebase.serverValue.TIMESTAMP,
        });

        const remainingLength = selectedItem.length - parseInt(data.lengthInMM);

        firebase
              .item(selectedItem.uid)
              .set({ ...selectedItem, length: remainingLength})

        const emailBody = waitingForApprovalMessage();

        sendEmail(data, "Venter for godkjenning", emailBody, WORKSHOP_EMAIL_ID);

        closeDialog();
        window.location.reload(true);
    };

    const openTheDialog = () => {
        return (
            <ReserveDialog
                item={selectedItem}
                open={openDialog}
                onClose={handleClose}
                onReserve={handleReserve}
                profiles={profiles}
                surfaces={surfaces}
                locations={locations}
                reserves={reserves}
                firebase={firebase}
            />
        );
    };

    reserves
        .filter(r => r.approved === RESERVED)
        .forEach(reserve => {
            const profile = profiles.find(
                profile => profile.uid === reserve.profile,
            );
            const localLocation = locations.find(
                location => location.uid === reserve.location,
            );
            const localSurface = surfaces.find(
                surface => surface.uid === reserve.surface,
            );

            const found = itemList.find(il => il.uid === reserve.itemId);
            if (!profile) {
                console.log("Profile", reserve);
            }
            if (found) {
                itemList.push({
                    uid: reserve.uid,
                    profile: profile?.uid,
                    length: reserve.length,
                    count: reserve.count,
                    surface: localSurface ? localSurface.uid : "",
                    projectNumber: reserve.projectNumber,
                    location: localLocation ? localLocation.uid : "",
                    parentId: reserve.itemId,
                    comment: reserve.comment,
                    approved: reserve.approved,
                });
            }
        });

    const actions = [
        rowData => ({
            icon: tableIcons.AddShoppingCart,
            tooltip: "Reserve",
            disabled: rowData.hasOwnProperty("comment"),
            onClick: (event, rowData) => {
                //setSelectedItem(rowData);
                //setOpenDialog(true);
                onClickReserve(rowData);
            },
        }),
    ];

    const onClickReserve = rowData => {
        setSelectedItem(rowData);
        setOpenDialog(true);
    };

    const getReservationColor = rowData => {
        if (rowData.approved === APPROVED) {
            return "lightgreen";
        } else if (rowData.approved === DISAPPROVED) {
            return "grey";
        } else {
            return "orange";
        }
    };

    const getLocations = () => {
        return locations.reduce(
            (obj, item) => Object.assign(obj, { [item.uid]: item.text }),
            {},
        );
    };

    const getProfiles = () => {
        return profiles.reduce(
            (obj, item) => Object.assign(obj, { [item.uid]: item.name }),
            {},
        );
    };

    const getSurfaces = () => {
        return surfaces.reduce(
            (obj, item) => Object.assign(obj, { [item.uid]: item.text }),
            {},
        );
    };

    const columns = [
        {
            field: "url",
            title: "Reserve",
            editable: "never",
            render: rowData =>
                rowData.hasOwnProperty("comment") ? (
                    <Tooltip title="Reservert">
                        <div
                            style={{
                                display: "inline-block",
                                height: 25,
                                width: 25,
                                background: getReservationColor(rowData),
                                paddingLeft: 10,
                                marginLeft: 10,
                            }}
                        >
                            R
                        </div>
                    </Tooltip>
                ) : (
                    <IconButton
                        color="primary"
                        aria-label="Reserve"
                        onClick={() => onClickReserve(rowData)}
                    >
                        <AddShoppingCartIcon />
                    </IconButton>
                ),
        },
        { title: "Uid", field: "uid", hidden: true },
        {
            title: "Profil",
            field: "profile",
            editable: "never",
            lookup: getProfiles(),
        },
        { title: "Lengde", field: "length", editable: "never" },
        { title: "Antall", field: "count", editable: "never" },
        {
            title: "Overflate",
            field: "surface",
            editable: "never",
            lookup: getSurfaces(),
        },
        { title: "Projketnr", field: "projectNumber" },
        { title: "Lokasjon", field: "location", lookup: getLocations() },
    ];

    return (
        <div>
            <MaterialTable
                icons={tableIcons}
                title=""
                columns={columns}
                data={itemList}
                //actions={actions}
                parentChildData={(row, rows) =>
                    rows.find(a => a.uid === row.parentId)
                }
                localization={{
                    pagination: {
                        labelDisplayedRows: "{from}-{to} av {count}",
                        labelRowsSelect: "rad",
                    },
                    // header: {
                    //     actions: "Reserve",
                    // },
                    body: {
                        emptyDataSourceMessage: "Ingen overflate ennå",
                        editRow: {
                            saveTooltip: "Lagre",
                            cancelTooltip: "Avbryt",
                            deleteText:
                                "Er du sikker på at du vil slette denne overflate?",
                        },
                        deleteTooltip: "Slett",
                        editTooltip: "Rediger",
                    },
                    toolbar: {
                        searchPlaceholder: "Søk",
                        searchTooltip: "Søk",
                    },
                }}
                editable={
                    (authUser.roles[ADMIN] === ADMIN ||
                        authUser.roles[WS] === WS) && {
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                if (oldData) {
                                    debugger;
                                    firebase.item(oldData.uid).set({
                                        ...oldData,
                                        projectNumber: newData.projectNumber,
                                        location: newData.location,
                                        count: newData.count,
                                    });
                                    resolve();
                                }
                            }),
                    }
                }
            />
            {openDialog && openTheDialog()}
        </div>
    );
};

const Items = withFirebase(ItemBase);
const condition = authUser =>
    authUser &&
    authUser.roles &&
    (authUser.roles[PL] === PL ||
        authUser.roles[ADMIN] === ADMIN ||
        authUser.roles[WS] === WS);

export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase,
)(Item);

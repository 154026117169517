import React, { useState, useEffect } from "react";
import {
    withAuthorization,
    AuthUserContext,
    withEmailVerification,
} from "../../Session";
import { compose } from "recompose";
import { withFirebase } from "../../components/Firebase";
import { tableIcons } from "../../components/TableIcons/TableIcons";
import { Typography, CircularProgress, Tooltip } from "@material-ui/core";
import MaterialTable from "material-table";
import Add from "@material-ui/icons/Add";
import NewProjectForm from "./NewProjectForm";
import Status from "./status";
import sendEmail from "../../mail/sendEmail";
import useStyles from "./styles";

const ADMIN = "ADMIN";
const WS = "WS";
const PL = "PL";

const ProgressComponent = () => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Typography variant="h3" className={classes.title}>
                Pågående ordere verksted
            </Typography>
            <Progress />
        </React.Fragment>
    );
};

function ProgressBase(props) {
    const [showAddProjectForm, setShowAddProjectForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            await onListenForItems();
            await onListenForUsers();
            setIsLoading(false);
        };

        fetchData();
    }, []);

    const onListenForItems = async () => {
        props.firebase
            .progresses()
            .orderByChild("createdAt")
            .on("value", snapshot => {
                const itemObject = snapshot.val();
                if (itemObject) {
                    const itemList = Object.keys(itemObject).map(key => ({
                        ...itemObject[key],
                        uid: key,
                    }));

                    const filteredItemList = itemList.filter(
                        d => d.status !== "doneFromWorkshope",
                    );

                    setIsLoading(false);
                    setData(filteredItemList);
                } else {
                    setIsLoading(false);
                    const filteredItemList = data.filter(
                        d => d.status !== "doneFromWorkshope",
                    );
                    setData(filteredItemList);
                }
            });
    };

    const onListenForUsers = () => {
        setIsLoading(true);

        props.firebase
        .users()
        .orderByChild('createdAt')
        .on('value', (snapshot) => {
            const userObject = snapshot.val();
            if (userObject) {
                const userList = Object.keys(userObject).map(
                    (key) => ({
                        ...userObject[key],
                        uid: key,
                    }),
                );

                setIsLoading(false);
                setUsers(userList);
            } else {
                setIsLoading(false);
                setUsers(users);
            }
        });
    };


    const handleOnNewProjectFormClose = () => {
        setShowAddProjectForm(false);
    };

    const addProjectForm = (authUser, firebase) => {
        return (
            <NewProjectForm
                open={true}
                onClose={handleOnNewProjectFormClose}
                authUser={authUser}
                firebase={firebase}
                users={users}
            />
        );
    };

    const getRemainingHours = rowData => {
        const { status } = rowData;

        if (isNaN(rowData.workshopHours )) {
            return "-";
        }

        const statusValue = getStatusValue(status);
        if (statusValue === 0) {
            return rowData.workshopHours;
        }

        const result = rowData.workshopHours  - ( rowData.workshopHours * statusValue) / 100;

        return parseFloat(result).toFixed(2);
    };

    const getStatusValue = status => {
        switch (status) {
            case "ten":
                return 10;
            case "twenty":
                return 20;
            case "thirty":
                return 30;
            case "forty":
                return 40;
            case "fifty":
                return 50;
            case "sixty":
                return 60;
            case "seventy":
                return 70;
            case "eighty":
                return 80;
            case "nighty":
                return 90;
            case "oneHundred":
                return 100;
            case "doneFromWorkshope":
                return 100;
            default:
                return 0;
        }
    };


    const convertArrayOfObject = (u) => {
        const mapped = u.map(m => ({ [m.uid]: m.username }));
        return Object.assign({}, ...mapped);

    };

    const columns = [
        { title: "Prosjekt", field: "projectName" },
        { title: "P.nr", field: "projectNr" },
        {
            title: "Beskrivelse",
            field: "description",
            render: rowData => (
                <div
                    style={{
                        width: 100,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                    }}
                >
                    <Tooltip title={<span style={{ fontSize: "20px", color: "white" }}>{rowData.description}</span>}>
                        <div>{rowData.description}</div>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: "PL",
            field: "projectLeader",
            lookup:  convertArrayOfObject(users) 
        },
        {
            title: "Status",
            field: "status",
            lookup: Status,
        },
        {
            title: "Vekt(kg)",
            field: "staalWeight",
        },
         {
            title: "Timer i verksted",
            field: "workShopHours",
            //editable: "never",
            render: rowData => <span> {rowData.workshopHours} </span>,
        },

        {
            title: "Tegninger klar",
            field: "drawingReadyDate",
            type: "date",
            render: rowData => (
                <span>
                    {rowData.drawingReadyDate
                        ? new Date(rowData.drawingReadyDate).toLocaleDateString(
                              "no-NB",
                          )
                        : ""}
                </span>
            ),
        },

        {
            title: "Stål leveranse",
            field: "staalDeliveryDate",
            type: "date",
            render: rowData => (
                <span>
                    {rowData.staalDeliveryDate
                        ? new Date(
                              rowData.staalDeliveryDate,
                          ).toLocaleDateString("no-NB")
                        : new Date().toLocaleDateString("no-NB")}
                </span>
            ),
        },

        {
            title: "Stål ut",
            field: "staalExpireDate",
            type: "date",
            render: rowData => (
                <span>
                    {rowData.staalExpireDate
                        ? new Date(rowData.staalExpireDate).toLocaleDateString(
                              "no-NB",
                          )
                        : ""}
                </span>
            ),
        },
        {
            title: "Gjenstående timeverk",
            field: "remainingHours",
            editable: "never",
            render: rowData => <span> {getRemainingHours(rowData)} </span>,
        }
    ];

    let itemList = [];
    const getItems = () => {
        data.sort((a, b) => b.createdAt - a.createdAt).forEach(
            (item, index) => {
                if (data) {
                    itemList.push({
                        uid: item.uid,
                        projectName: data.projectName,
                        projectNr: data.projectNr,
                        projectLeader: data.projectLeader,
                        staalWeight: data.staalWeight,
                        pricePerKg: data.pricePerKg,
                        drawingReadyDate: data.drawingReadyDate,
                        staalDeliveryDate: data.staalDeliveryDate,
                        staalExpireDate: data.staalExpireDate,
                        workShopHours: data.workShopHours,
                        remainingHours: data.remainingHours,
                        employeedPerWeek: data.employeedPerWeek,
                        status: data.status,
                        description: data.description,
                        createdAt: data.createdAt,
                        updatedAt: props.firebase.serverValue.TIMESTAMP,
                    });
                }
            },
        );
    };

    const localization = {
        pagination: {
            labelDisplayedRows: "{from}-{to} av {count}",
            labelRowsSelect: "rad",
        },
        body: {
            emptyDataSourceMessage: "Ingen overflate ennå",
            editRow: {
                saveTooltip: "Lagre",
                cancelTooltip: "Avbryt",
                deleteText: "Er du sikker på at du vil slette denne overflate?",
            },
            deleteTooltip: "Slett",
            editTooltip: "Rediger",
        },
        toolbar: {
            searchPlaceholder: "Søk",
            searchTooltip: "Søk",
        },
    };

    const convertDateTimeToIso = field => {
        return new Date(field).toISOString().slice(0, 10);
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    const createEmailBody = (data, email) => {
        return `
        <!DOCTYPE html>
        <html>
          <head></head>
          <body>
              <pre>
                <h4>Hei ${email}</h4>
                <p> prosjekt nummer: ${data.projectNr} </p>
                <p> prosjekt navn: ${data.projectName} </p>
                <p> er ferdig og klar for henting fra verksted! </p>
              </pre>
          </body>
        </html>
      `;
    };

    getItems();

    return (
        <AuthUserContext.Consumer>
            {authUser => (
                <div style={{ paddingTop: 20 }}>
                    <MaterialTable
                        icons={tableIcons}
                        title=""
                        columns={columns}
                        data={data}
                        localization={localization}
                        options={{
                            headerStyle: {
                                backgroundColor: "#01579b",
                                color: "#FFF",
                            },
                        }}
                        actions={[
                            {
                                icon: Add,
                                tooltip: "Add User",
                                isFreeAction: true,
                                onClick: event => setShowAddProjectForm(true),
                            },
                        ]}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise(resolve => {
                                    if (oldData) {
                                        props.firebase
                                            .progress(oldData.uid)
                                            .set({
                                                ...newData,
                                                updatedAt:
                                                    props.firebase.serverValue
                                                        .TIMESTAMP,
                                                drawingReadyDate:
                                                    typeof newData.drawingReadyDate ===
                                                    "object"
                                                        ? convertDateTimeToIso(
                                                              newData.drawingReadyDate,
                                                          )
                                                        : newData.drawingReadyDate,
                                                staalDeliveryDate:
                                                    typeof newData.staalDeliveryDate ===
                                                    "object"
                                                        ? convertDateTimeToIso(
                                                              newData.staalDeliveryDate,
                                                          )
                                                        : newData.staalDeliveryDate,
                                                staalExpireDate:
                                                    typeof newData.staalExpireDate ===
                                                    "object"
                                                        ? convertDateTimeToIso(
                                                              newData.staalExpireDate,
                                                          )
                                                        : newData.staalExpireDate,
                                            });

                                        if (oldData.status !== newData.status) {

                                            // insert to progress log
                                             props.firebase.progressLogs().push({
                                                progressId : newData.uid,
                                                staalWeight: newData.staalWeight,
                                                //pricePerKg: newData.pricePerKg,
                                                status: newData.status,
                                                createdAt: props.firebase.serverValue.TIMESTAMP

                                            });

                                        }

                                        if (newData.status === "oneHundred" && oldData.status !== "oneHundred") {
                                            const user = users.filter(f => f.uid === newData.projectLeader);
                                            if (user[0]) {
                                                const theUser = user[0];
                                               const emailBody = createEmailBody(newData, theUser.email);
                                               sendEmail(newData, "Klar for henting", emailBody, theUser.email);

                                            }
                                        }
                                        resolve();
                                    }
                                }),
                            onRowDelete: oldData =>
                                new Promise(resolve => {
                                    props.firebase
                                        .progress(oldData.uid)
                                        .remove();
                                    resolve();
                                }),
                        }}
                    />
                    {showAddProjectForm &&
                        addProjectForm(authUser, props.firebase)}
                </div>
            )}
        </AuthUserContext.Consumer>
    );
}

const Progress = withFirebase(ProgressBase);
const condition = authUser =>
    authUser &&
    authUser.roles &&
    (authUser.roles[ADMIN] === ADMIN || authUser.roles[WS] === WS || authUser.roles[PL] === PL);

export default compose(
    withAuthorization(condition),
    withEmailVerification,
    withFirebase,
)(ProgressComponent);

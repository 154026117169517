import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {HEA, HEB, Likebenet_Vinkel, Ulikebenet_Vinkel, UNP, UPE, IPE, HUP_Kvadratiske, HUP_Rektangulaere, Runde} from '../../images';
import { FullscreenExit, DriveEta } from "@material-ui/icons";
import useStyles from "./styles";


const returnModule = (img) => {
    switch(img) {
        case "Likebenet Vinkel":
            return Likebenet_Vinkel;
        case "Ulikebenet Vinkel":
            return Ulikebenet_Vinkel;
        case "UNP":
            return UNP;
        case "UPE":
            return UPE;
        case "IPE":
            return IPE;
        case "HUP Kvadratiske":
            return HUP_Kvadratiske;
        case "HUP Rektangulaere":
            return HUP_Rektangulaere;
        case "HEA":
            return HEA;
        case "HEB":
            return HEB;
        case "rør":
            return Runde;
        default:
            return HEA;
    }
};

const CustomMenuItem = ({ profile, clickHandler }) => {

    var classes = useStyles();

    var [hover, setHover] = useState(false);

    const toggleEnterHover = () => {
        setHover(true);
    };

    const toggleLeaveHover = () => {
        setHover(false);
    };


    //const image = returnModule(profile.img);

    return (
        <div 
            onClick={() => clickHandler(profile.type)}
            onMouseEnter={toggleEnterHover} 
            onMouseLeave={toggleLeaveHover} 
            style={{ display: 'flex', justifyContent: 'space-between', lineHeight: "30px",
             background: hover ? 'lightgrey' : '' }}
        >  
            {/* {profile.img && <div><img src={image} style={{ width: 90, height: 90}}/></div> } */}
            <div style={{margin: 'auto'}}>{profile.label}</div>
        </div>
    );

};

export default CustomMenuItem;
import React, { useContext, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

const ProjectDetailForm = ({ open, onClose, authUser, firebase, data }) => {
    const [workShopHours, setWorkShopHours] = useState(0);
    const [drawingResponsible, setDrawingResponsible] = useState("");
    const [remainingHours, setRemainingHours] = useState(0);
    const [status, setStatus] = useState("");
    const [pricePerKg, setPricePerKg] = useState(0);
    const [description, setDescription] = useState("");
    const [employeedPerWeek, setEmployeedPerWeek] = useState(0);

    const handleChange = e => {
        switch (e.currentTarget.name) {
            case "status":
                setStatus(e.target.value);
                break;
            case "pricePerKg":
                setPricePerKg(parseFloat(e.target.value));
                break;
            case "workShopHours":
                setWorkShopHours(parseFloat(e.target.value));
                break;
            case "drawingResponsible":
                setDrawingResponsible(e.target.value);
                break;
            case "remainingHours":
                setRemainingHours(parseFloat(e.target.value));
                break;
            case "employeedPerWeek":
                setEmployeedPerWeek(e.target.value);
                break;
            case "description":
                setDescription(e.target.value);
                break;
            default:
                throw new Error("not found");
        }
    };

    const handleFormSubmit = event => {
        event.preventDefault();

        firebase.progressDetails().push({
            createdBy: authUser.uid,
            progressId: data.uid,
            projectName: data.projectName,
            projectNr: data.projectNr,
            pricePerKg: pricePerKg,
            workShopHours: workShopHours,
            drawingResponsible: drawingResponsible,
            status: status,
            remainingHours: remainingHours,
            employeedPerWeek: employeedPerWeek,
            description: description,
            createdAt: firebase.serverValue.TIMESTAMP,
        });

        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Prosjekt"}</DialogTitle>
            <DialogContent>
                <form onSubmit={event => handleFormSubmit(event, authUser)}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="project"
                                label="Prosjekt"
                                type="text"
                                value={data.projectName}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="projectnr"
                                label="Prosjektnr"
                                type="text"
                                value={data.projectNr}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="pricePerKg"
                                label="Pris Kr/Kg"
                                type="number"
                                value={pricePerKg}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="workShopHours"
                                label="Timer i verksted"
                                type="number"
                                value={workShopHours}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="drawingResponsible"
                                label="Ansv. Tegninger"
                                type="text"
                                value={drawingResponsible}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="status"
                                label="Status"
                                type="text"
                                value={status}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                name="remainingHours"
                                label="Gjenstående timeverk"
                                type="number"
                                value={remainingHours}
                                onChange={e => handleChange(e)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="employeedPerWeek"
                                label="Bemaning/Uke"
                                type="text"
                                value={employeedPerWeek}
                                onChange={e => handleChange(e)}
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="description"
                                label="description"
                                type="textarea"
                                value={description}
                                onChange={e => handleChange(e)}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={4}
                        style={{ flexDirection: "row-reverse" }}
                    >
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                onClick={onClose}
                                color="secondary"
                            >
                                Avbryt
                            </Button>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Lagre
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default ProjectDetailForm;

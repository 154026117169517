import React, { useState, useEffect } from 'react';
import { Typography, CircularProgress} from "@material-ui/core";
import { compose } from "recompose";
import { withAuthorization, AuthUserContext, withEmailVerification} from '../../Session';
import MaterialTable from 'material-table';
import { withFirebase } from '../../components/Firebase';
import { tableIcons } from '../../components/TableIcons/TableIcons';
import useStyles from "./styles";

const ADMIN = 'ADMIN';
const WS = 'WS';

const Surface = () => {
    var classes = useStyles();

    return (
        <React.Fragment>
            <Typography variant="h3" className={classes.title}>
                Overflate
            </Typography>
            <Surfaces />
        </React.Fragment>
    );
}

function SurfaceBase (props) {

    var classes = useStyles();

    var [isLoading, setIsLoading] = useState(false);
    var [text, setText] = useState("");
    var [surfaces, setSurfaces] = useState([]);
    var [error, setError] = useState(null);

    useEffect(() => {
        onListenForSurfaces()
    }, []);
    const onChangeText = (event) => {
        setError("");
        setText(event.target.value);
    };

    
    const onCreateProfile = (event, authUser) => {
        setIsLoading(true);
        
        const isDuplicate = surfaces.some(surface => surface.text.toUpperCase() === text.toUpperCase());

        if (isDuplicate) {
            setError(`Det finnes allerede ${text}`);
            setIsLoading(false);
            setText("");
            event.preventDefault();
            return;
        }

        props.firebase.surfaces().push({
            userId: authUser.uid,
            text: text,
            createdAt: props.firebase.serverValue.TIMESTAMP
        });
        setIsLoading(false);
        setText("");
        event.preventDefault();
    };

    const onListenForSurfaces = () => {
        setIsLoading(true);

        props.firebase
        .surfaces()
        .orderByChild('createdAt')
        .on('value', (snapshot) => {
            const surfaceObject = snapshot.val();
            if (surfaceObject) {
                const surfaceList = Object.keys(surfaceObject).map(
                    (key) => ({
                        ...surfaceObject[key],
                        uid: key,
                    }),
                );

                setIsLoading(false);
                setSurfaces(surfaceList);
            } else {
                setIsLoading(false);
                setSurfaces(surfaces);
            }
        });
    };

    return (
        <AuthUserContext.Consumer>
            {(authUser) => (
                <div>
                    <div>
                        {isLoading && <CircularProgress size={26} /> }
                    </div>
                    <div className={classes.error}>
                        {error != null &&  error }
                    </div>
                    <form onSubmit={(event) => onCreateProfile(event, authUser)} className={classes.myForm}>
                        <input
                            type="text"
                            value={text}
                            onChange={onChangeText}
                            className={classes.input}
                        />
                        <button type="submit" className={classes.mybutton}>Lagre</button>
                    </form>
                    {surfaces ? (
                        <SurfaceList surfaces={surfaces} authUser={authUser} firebase={props.firebase} />
                    ): <div> There are no profiles yet </div>}
                </div>
            )}
        </AuthUserContext.Consumer>
    );
}

const SurfaceList = ({surfaces, authUser, firebase}) => {
    let surfaceList = [];
    surfaces.forEach(surface => {
        surfaceList.push({uid: surface.uid, text: surface.text});
    });
    
    const columns = [
        { title: 'Uid', field: 'uid', hidden: true},
        { title: 'Overflate', field: 'text' }
    ];

    return (
        <MaterialTable
            icons={tableIcons}
            title=""
            columns={columns}
            data={surfaceList}
            localization={{
                pagination: {
                    labelDisplayedRows: "{from}-{to} av {count}",
                    labelRowsSelect: "rad"
                },
                header: {
                    actions: 'Handlinger'
                },
                body: {
                    emptyDataSourceMessage: 'Ingen overflate ennå',
                    editRow: {
                        saveTooltip: "Lagre",
                        cancelTooltip: "Avbryt",
                        deleteText: "Er du sikker på at du vil slette denne overflate?"
                    },
                    deleteTooltip: "Slett",
                    editTooltip: "Rediger"
                },
                toolbar: {
                    searchPlaceholder: "Søk",
                    searchTooltip: 'Søk'
                }

            }}
            // editable={{
            // onRowUpdate: (newData, oldData) =>
            //     new Promise((resolve) => {
            //         if (oldData) {
            //             firebase.surface(newData.uid).set({...newData});
            //             resolve();
            //         }
            //     }),
            // onRowDelete: (oldData) =>
            //     new Promise((resolve) => {
            //         firebase.surface(oldData.uid).remove();
            //         resolve();
            //     }),
            // }}
      />
    );
};

const Surfaces = withFirebase(SurfaceBase);
const condition = authUser =>
    authUser && authUser.roles && (authUser.roles[ADMIN] === ADMIN || authUser.roles[WS] === WS);

export default compose(
    withAuthorization(condition),
    withEmailVerification,
    withFirebase,
  )(Surface);
import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    materialTable: {
        paddingTop: 30,
    },
    title: {
        fontWeight: 500,
        textAlign: "center",
        marginTop: theme.spacing(4),
    },
    myForm: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: "30px",
    },
    input: {
        flex: "2 0 0",
        padding: "12px 20px",
        margin: "8px 8px 0 0",
        display: "inline-block",
        border: "1px solid #ccc",
        borderRadius: "4px",
        boxSizing: "border-box",
    },
    mybutton: {
        flex: "1 0 0",
        margin: "8px 0 0 0",
    },
    error: {
        color: "red",
    },
    newitem: {
        display: "flex",
        justifyContent: "flex-end",
        paddingTop: "20px",
        paddingBottom: "20px",
    },
}));

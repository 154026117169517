import React, { useState, useEffect, useContext } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import {
    Home as HomeIcon,
    Assignment as AssignmentIcon,
    MyLocation as MyLocationIcon,
    SupervisedUserCircle as SupervisedUserCircleIcon,
    Layers as LayersIcon,
    Store as StoreIcon,
    ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { AuthUserContext } from "../../Session";
import { useUserDispatch, signOut } from "../../context/UserContext";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
    {
        id: 1,
        label: "Dashboard",
        link: "/app/dashboard",
        icon: <HomeIcon />,
        roles: ["WS", "PL", "ADMIN"],
    },
    //{ id: 1, label: "Home", link: "/app/home", icon: <HomeIcon />, roles: ["WS", "PL", "ADMIN"]},
    {
        id: 2,
        label: "Varelager",
        link: "/app/items",
        icon: <StoreIcon />,
        roles: ["WS", "PL"],
    },
    {
        id: 3,
        label: "Profil",
        link: "/app/profil",
        icon: <AssignmentIcon />,
        roles: ["WS", "PL"],
    },
    {
        id: 4,
        label: "Lokasjon",
        link: "/app/lokasjon",
        icon: <MyLocationIcon />,
        roles: ["WS"],
    },
    {
        id: 5,
        label: "Overflate",
        link: "/app/overflate",
        icon: <LayersIcon />,
        roles: ["WS"],
    },
    {
        id: 6,
        label: "Reservasjon",
        link: "/app/reservations",
        icon: <AddShoppingCartIcon />,
        roles: ["WS"],
    },
    {
        id: 7,
        label: "Godkjent",
        link: "/app/approved",
        icon: <ThumbUpIcon />,
        roles: ["WS"],
    },
    {
        id: 8,
        label: "Levert",
        link: "/app/delivered",
        icon: <AssignmentTurnedInIcon />,
        roles: ["WS"],
    },
    {
        id: 9,
        label: "Bruker",
        link: "/app/bruker",
        icon: <SupervisedUserCircleIcon />,
        roles: ["ADMIN"],
    },
    {
        id: 10,
        label: "Pågående ordere",
        link: "/app/progress",
        icon: <AccessTimeIcon />,
        roles: ["WS", "PL"],
    },
];

function Sidebar({ location, firebase, history }) {
    var classes = useStyles();
    var theme = useTheme();

    const authUser = useContext(AuthUserContext);

    // global
    var { isSidebarOpened } = useLayoutState();
    var layoutDispatch = useLayoutDispatch();
    var userDispatch = useUserDispatch();

    // local
    var [isPermanent, setPermanent] = useState(true);

    useEffect(function() {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
            window.removeEventListener("resize", handleWindowWidthChange);
        };
    });

    const isAuthorized = (authUser, link) => {
        if (authUser && authUser.roles) {
            return Object.keys(authUser.roles).some(role =>
                role === "ADMIN"
                    ? true
                    : link.roles.includes(authUser.roles[role]),
            );
        }
        signOut(userDispatch, history);
        return false;
    };

    return (
        <Drawer
            variant={isPermanent ? "permanent" : "temporary"}
            className={classNames(classes.drawer, {
                [classes.drawerOpen]: isSidebarOpened,
                [classes.drawerClose]: !isSidebarOpened,
            })}
            classes={{
                paper: classNames({
                    [classes.drawerOpen]: isSidebarOpened,
                    [classes.drawerClose]: !isSidebarOpened,
                }),
            }}
            open={isSidebarOpened}
        >
            <div className={classes.toolbar} />
            <div className={classes.mobileBackButton}>
                <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
                    <ArrowBackIcon
                        classes={{
                            root: classNames(
                                classes.headerIcon,
                                classes.headerIconCollapse,
                            ),
                        }}
                    />
                </IconButton>
            </div>
            <List className={classes.sidebarList}>
                {structure.map(
                    link =>
                        isAuthorized(authUser, link) && (
                            <SidebarLink
                                key={link.id}
                                location={location}
                                isSidebarOpened={isSidebarOpened}
                                {...link}
                            />
                        ),
                )}
            </List>
        </Drawer>
    );

    // ##################################################################
    function handleWindowWidthChange() {
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.md;
        var isSmallScreen = windowWidth < breakpointWidth;

        if (isSmallScreen && isPermanent) {
            setPermanent(false);
        } else if (!isSmallScreen && !isPermanent) {
            setPermanent(true);
        }
    }
}

//const condition = (authUser) => authUser;

export default withRouter(Sidebar);

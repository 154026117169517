import React, { useContext } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import { AuthUserContext } from "../../Session";
// pages
import Dashboard from "../../pages/dashboard";
import Home from "../../pages/home/Home";
import Surface from "../../pages/surface/Surface";
import Profile from "../../pages/profiles/Profile";
import Location from "../../pages/location/Location";
import User from "../../pages/users/User";
import Workshop from "../../pages/workshop/Workshop";
import Item from "../../pages/items/Items";
import Reserve from "../../pages/reserve/reserve";
import Reservations from "../../pages/reserve/index";
import Approved from "../../pages/approved/index";
import Delivered from "../../pages/delivered/index";
import Progress from "../../pages/progress/index";
import ProgressDetail from "../../pages/progressDetail/index";

// context
import { useLayoutState } from "../../context/LayoutContext";

function Layout(props) {
    var classes = useStyles();
    // global
    var layoutState = useLayoutState();

    return (
        <div className={classes.root}>
            <>
                <Header history={props.history} />
                <Sidebar />
                <div
                    className={classnames(classes.content, {
                        [classes.contentShift]: layoutState.isSidebarOpened,
                    })}
                >
                    <div className={classes.fakeToolbar} />
                    <Switch>
                        <Route path="/app/dashboard" component={Dashboard} />
                        <Route path="/app/home" component={Home} />
                        <Route path="/app/overflate" component={Surface} />
                        <Route path="/app/profil" component={Profile} />
                        <Route path="/app/lokasjon" component={Location} />
                        <Route path="/app/bruker" component={User} />
                        <Route path="/app/workshop" component={Workshop} />
                        <Route path="/app/items" component={Item} />
                        <Route path="/app/reserve" component={Reserve} />
                        <Route
                            path="/app/reservations"
                            component={Reservations}
                        />
                        <Route path="/app/approved" component={Approved} />
                        <Route path="/app/delivered" component={Delivered} />
                        <Route
                            exact
                            path="/app/progress"
                            component={Progress}
                        />
                        <Route
                            exact
                            path="/app/progress/:id"
                            component={ProgressDetail}
                        />
                    </Switch>
                </div>
            </>
        </div>
    );
}

export default withRouter(Layout);

import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  title: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  myForm : {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "30px"
  },
  input : {
    flex: "2 0 0",
    padding: "12px 20px",
    margin: "8px 8px 0 0",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box",
  },
  li : {
    minHeight: "50px",

  },
  mybutton : {
    flex: "1 0 0",
    margin: "8px 0 0 0",
  },
  error : {
    color: "red"
  },
  formControls: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    paddingLeft: "30px"
  },
  formControl: {
    width: 260,
    //marginTop: 30
  },
  formInputControl: {
    width: 240,
    paddingRight: 30,
    marginTop: 30,

  },
  subProfileImage: {
    marginTop: 30 
  },
  newitem: {
    display: "flex",
    justifyContent: "space-between", 
    paddingTop: "20px",
    paddingBottom: "20px"
  },
  btn_new_item: {
    display: "flex",
    justifyContent: "flex-end"
  }
}));
